/* This class below will remove if we push to production */
.this-hidden-area {
  display: none !important;
}

body.kyc-body {
  min-width: 275px;
  background: #F4F4F4;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -.01em;
  color: #1A1D1F;
}
body.kyc-body.dark-mode {
  background: #111315;
  color: #FCFCFC;
}
body.kyc-body.dark-mode ::-moz-selection {
  background: #0069f6;
  color: #ffffff;
}
body.kyc-body.dark-mode ::selection {
  background: #0069f6;
  color: #ffffff;
}

.media__content-kyc p + * {
  margin-top: 16px;
}
.media__content-kyc ul {
  padding-left: 16px;
  list-style: circle;
}
.media__content-kyc ul li + li {
  margin-top: 8px;
}
.media__content-kyc strong {
  color: #1A1D1F;
}
body.kyc-body.dark-mode .media__content-kyc strong {
  color: #6F767E;
}

.refund__content-link a {
  font-weight: 600;
}

.title__clear-bar {
  padding-left: 0;
}
.title__clear-bar::before {
  display: none;
}

.resend-confirmation-link {
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.resend-confirmation-link,
.refund__content-link a {
  color: #6F767E;
}

.refund__content-link a:hover, .refund__content-link a:focus {
  color: #1A1D1F;
}
body.kyc-body.dark-mode .refund__content-link a:hover, body.kyc-body.dark-mode .refund__content-link a:focus {
  color: #FCFCFC;
}

.resend-confirmation-link:hover,
.resend-confirmation-link:focus,
.field__checkbox-kyc .checkbox__text a,
.login__info-kyc a.has-active {
  color: #2A85FF;
}

.cursor-pointer {
  cursor: pointer;
}

.refund__content ol,
.refund__content ul {
  margin-left: 16px;
}

.legaldisclaimers__list li li::before {
  display: none;
}
.legaldisclaimers__list a {
  color: #2A85FF;
}
.legaldisclaimers__list a:hover, .legaldisclaimers__list a:focus {
  color: #0069f6;
}
.legaldisclaimers__list li ul {
  margin-top: 16px;
  margin-bottom: 16px;
}
.legaldisclaimers__list li li {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.legaldisclaimers__list li li + li {
  margin-top: 12px !important;
}

.drawer_report__list {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 24px;
}
@media only screen and (max-width: 1558px) {
  .drawer_report__list {
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }
}
.drawer_report__list .calendar__item {
  width: 100%;
  margin-top: 0;
}

.whitespace__initial {
  white-space: initial;
}

.nowrap {
  white-space: nowrap;
}

.item-content__middle,
.refund__item.refund__item-middle_content {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.d__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex__row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
}
.flex__column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
}
.flex__wrap {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.flex__nowrap {
  flex__wrap: nowrap;
}
.flex__justify_end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.flex__justify_start {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.flex__justify_center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.flex__justify_between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.flex__align_start {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
}
.flex__align_center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex__align_end {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
}

.item__flex_1 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.icon-16 {
  height: 16px !important;
  width: 16px !important;
}

.icon-24 {
  height: 24px !important;
  width: 24px !important;
}

.space {
  display: block;
  width: 100%;
  position: relative;
}

.space__8 {
  height: 8px;
}

.space__16 {
  height: 16px;
}

.space__32 {
  height: 32px;
}

.space__12 {
  height: 12px;
}

.space__24 {
  height: 24px;
}

.space__48 {
  height: 48px;
}

.space__10 {
  height: 10px;
}

.space__20 {
  height: 20px;
}

.space__40 {
  height: 40px;
}

.nospace__top {
  margin-top: 0 !important;
}
.nospace__bottom {
  margin-bottom: 0 !important;
}
.nospace__left {
  margin-left: 0 !important;
}
.nospace__right {
  margin-right: 0 !important;
}

.p__0 {
  padding: 0;
}
.p-8, .p__8 {
  padding: 8px;
}

.pt__0 {
  padding-top: 0;
}
.pt__24 {
  padding-top: 24px;
}

.pb__0 {
  padding-bottom: 0;
}

.pr__0 {
  padding-right: 0;
}

.mt__0 {
  margin-top: 0;
}

.mr__8 {
  margin-right: 8px;
}

.mb__0 {
  margin-bottom: 0;
}
.mb__0-important {
  margin-bottom: 0 !important;
}
.mb__8 {
  margin-bottom: 8px;
}

.bb__0 {
  border-bottom-width: 0;
}

.text__primary {
  color: #2A85FF;
}

.bg__primary {
  background-color: #2A85FF;
}

.text__green {
  color: #83BF6E;
}

.bg__green {
  background-color: #83BF6E;
}

.text__red {
  color: #FF6A55;
}

.bg__red {
  background-color: #FF6A55;
}

.text__orange {
  color: #FFBC99;
}

.bg__orange {
  background-color: #FFBC99;
}

.text__yellow {
  color: #ecb610;
}

.bg__yellow {
  background-color: #ecb610;
}

.text__gray {
  color: #6F767E;
}

.bg__gray {
  background-color: #6F767E;
}

.text__blue {
  color: #B1E5FC;
}

.bg__blue {
  background-color: #B1E5FC;
}

.text__purple {
  color: #8E59FF;
}

.bg__purple {
  background-color: #8E59FF;
}

.text__white {
  color: #ffffff;
}

.bg__white {
  background-color: #ffffff;
}

.text__white-important {
  color: #ffffff !important;
}

.wrapper-ratio__1-1 {
  padding-bottom: 100%;
}
.wrapper-ratio__3-2 {
  padding-bottom: 66.66%;
}
.wrapper-ratio__16-9 {
  padding-bottom: 56.25%;
}

.wrapper-ratio__1-1, .wrapper-ratio__3-2, .wrapper-ratio__16-9 {
  position: relative;
}
.wrapper-ratio__1-1 img,
.wrapper-ratio__1-1 iframe,
.wrapper-ratio__1-1 video, .wrapper-ratio__3-2 img,
.wrapper-ratio__3-2 iframe,
.wrapper-ratio__3-2 video, .wrapper-ratio__16-9 img,
.wrapper-ratio__16-9 iframe,
.wrapper-ratio__16-9 video {
  display: block;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
}

.text__center {
  text-align: center;
}
.text__left {
  text-align: left;
}
.text__right {
  text-align: right;
}

@media only screen and (max-width: 320px) {
  .smallscreen__text_small {
    font-size: 13px;
  }
}

.overflow__hidden {
  overflow: hidden;
}
.overflow-x__auto {
  overflow-x: auto;
}
.overflow-x__hidden {
  overflow-x: hidden;
}
.overflow-y__auto {
  overflow-y: auto;
}
.overflow-y__hidden {
  overflow-y: hidden;
}

.settings__clear {
  padding: initial;
  border-radius: 0;
  background: initial;
}
.settings__wrapper {
  padding-left: 0;
}
.settings__wrapper_2 {
  padding-left: 32px;
}
@media only screen and (max-width: 1023px) {
  .settings__wrapper_2 {
    padding-left: 0;
  }
}
.settings__kyc + .settings__kyc {
  margin-top: 24px;
}
.settings__content p + p {
  margin-top: 8px;
}

.card__head.card__head-kyc-1 {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.card__head.card__head-kyc-1 .card__head-content {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
  margin-top: 16px;
}

.card__head .card__title-has_icon .icon {
  fill: #6F767E;
}
body.kyc-body.dark-mode .card__head .card__title-has_icon .icon {
  fill: #FCFCFC;
}

.fill__red {
  fill: #FF6A55 !important;
}

.settings__fieldset .field__small-space.field:not(:last-child) {
  margin-bottom: 16px;
}
.settings__fieldset_have_card .card {
  background: transparent;
  padding: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.settings__list_multiple:last-child .settings__top:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}
.settings__list_multiple .settings__top-break {
  margin-left: 24px;
}

.field .field__helper,
.field .MuiFormHelperText-root {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 1.33333;
  font-weight: 500;
  color: #6F767E;
  margin-top: 8px;
}
.field__label .MuiBox-root {
  position: relative;
  top: 2px;
}
.field__label a {
  color: #2A85FF;
}
.field__label a:hover, .field__label a:focus {
  color: #0069f6;
}
.field__label .MuiBox-root .icon.lock {
  fill: #FFBC99;
}
.field__label p {
  display: inline;
}

.create__info a {
  color: #2A85FF;
}
.create__info a:hover, .create__info a:focus {
  color: #0069f6;
}

/* Fields divide */
.fields__divide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.fields__divide .field {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.fields__divide .field__token-eq {
  padding-left: 16px;
  padding-right: 16px;
}

.fields__divide .review__line {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100px;
          flex: 0 0 100px;
  max-width: 100px;
}

.field__wrap.field__wrap-icon-right .field__input {
  padding-right: 42px;
}

.field__wrap.field__wrap-icon-right .field__icon {
  left: initial;
  right: 0;
  pointer-events: initial;
}

.create__group_inline .field {
  width: initial;
  -ms-flex-preferred-size: initial;
      flex-basis: initial;
}

/* Set space on fields-group */
.settings__row.settings__row-fullwidth .field {
  -ms-flex-preferred-size: calc(100% - 16px);
      flex-basis: calc(100% - 16px);
  width: calc(100% - 16px);
}

.settings__row.flex__row.flex__wrap.have__fields {
  margin-bottom: -32px;
}
@media only screen and (max-width: 767px) {
  .settings__row.flex__row.flex__wrap.have__fields {
    margin-bottom: -16px;
  }
}

.settings__row.flex__row.flex__wrap.have__fields .field {
  margin-bottom: 32px;
}
@media only screen and (max-width: 767px) {
  .settings__row.flex__row.flex__wrap.have__fields .field {
    margin-bottom: 16px;
  }
}

.settings__row.flex__row.flex__wrap.have__space,
.settings__row.flex__row.flex__wrap.have__fields.have__space {
  margin-bottom: 32px;
}
@media only screen and (max-width: 767px) {
  .settings__row.flex__row.flex__wrap.have__space,
  .settings__row.flex__row.flex__wrap.have__fields.have__space {
    margin-bottom: 16px;
  }
}

.settings__row .field .field {
  width: 100%;
  margin: 0;
}

.settings__grid {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);
}

.settings__grid .field {
  width: 100%;
}

/* This field make the label after the field wrap and make all inline */
.field__inner-divide-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-flow: row-reverse;
          flex-flow: row-reverse;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.field__inner-divide-2 .field__label-75 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75px;
          flex: 0 0 75px;
  max-width: 75px;
  padding-left: 16px;
  margin-bottom: 0;
}

.field__inner-divide-2 .field__wrap {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

/* Field disable */
.field .field__input:disabled {
  color: #6F767E;
  cursor: not-allowed;
}
body.kyc-body.dark-mode .field .field__input:disabled {
  color: #FCFCFC;
}

/* Field radio content that have one line with field-label */
.field.field__inner-divide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

input[type="text"].field__input.field__input-white, input[type="email"].field__input.field__input-white {
  background-color: #ffffff;
}
body.kyc-body.dark-mode input[type="text"].field__input.field__input-white,
body.kyc-body.dark-mode input[type="email"].field__input.field__input-white {
  background-color: #111315;
}

.field-current_thumbnail .product__preview {
  cursor: default;
}

.field-has__endadorment .MuiFormControl-root {
  margin: 0;
  width: 100%;
}
.field-has__endadorment .MuiInputBase-root {
  height: 48px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  line-height: 1.6;
  border-radius: 12px;
  padding: 0 16px 0 10px;
  border: 2px solid #F4F4F4;
}
.field-has__endadorment .MuiInputBase-root:before, .field-has__endadorment .MuiInputBase-root:after {
  display: none;
}
.field-has__endadorment .MuiInputAdornment-root .MuiTypography-root {
  font-size: 15px;
  font-weight: bold;
}
.field-has__endadorment .field__wrap-white .MuiInputBase-root {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1A1D1F;
}
.field-has__endadorment .field__wrap-white .MuiInputAdornment-root .MuiTypography-root {
  color: #1A1D1F;
}

body.kyc-body.dark-mode .page__inner-onboarding .field__wrap-white .MuiInputBase-root {
  border-color: #272B30 !important;
  background-color: #111315 !important;
  color: #FCFCFC !important;
}
body.kyc-body.dark-mode .page__inner-onboarding .field__wrap-white .MuiInputBase-root.Mui-focused {
  border-color: #33383F !important;
}
body.kyc-body.dark-mode .page__inner-onboarding .field__wrap-white .MuiInputAdornment-root .MuiTypography-root {
  color: #F4F4F4;
}

.settings__label-preview .preview-inner {
  width: 75px;
  height: 75px;
  overflow: hidden;
}
.settings__label-preview .preview-inner img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.field embed {
  width: 100%;
}

/* Field File */
.file__wrap.file__wrap-white {
  background-color: white;
}

.file__wrap_text {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 1.33333;
  font-weight: 500;
  color: #6F767E;
  margin-top: 8px;
}

.file__wrap_text + button {
  margin-top: 16px;
}

.field__wrap-date .MuiInputBase-root::before, .field__wrap-date .MuiInputBase-root::after,
.field__wrap-date .MuiFromControl-root::before,
.field__wrap-date .MuiFromControl-root::after {
  display: none;
}
.field__wrap-date .MuiFormControl-root {
  padding: 0;
  border: none;
  line-height: initial;
  font-size: initial;
  border-radius: initial;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.field__wrap-date .MuiInputBase-root {
  width: 100%;
  height: 48px;
  padding: 0 10px;
  border-radius: 12px;
  border: 2px solid #F4F4F4;
  background-color: #F4F4F4;
  padding-right: 58px;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
  color: #1A1D1F;
  -webkit-transition: all .2s;
  transition: all .2s;
}
body.kyc-body.dark-mode .field__wrap-date .MuiInputBase-root {
  border-color: #272B30 !important;
  background-color: #272B30 !important;
  color: #FCFCFC !important;
}
.field__wrap-date .MuiInputBase-root.Mui-focused {
  border-color: #9A9FA5;
  background-color: #ffffff;
}
body.kyc-body.dark-mode .field__wrap-date .MuiInputBase-root.Mui-focused {
  border-color: #33383F !important;
  background-color: #111315 !important;
}
body.kyc-body.dark-mode .field__wrap-date .MuiInputBase-root.Mui-focused .MuiIconButton-root {
  background-color: #111315 !important;
}
.field__wrap-date .MuiIconButton-root {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 48px;
  padding: 0;
  padding-top: 1px;
  border-radius: 0px 10px 10px 0px;
  background: #F4F4F4;
  color: #1A1D1F;
  border: none;
}
body.kyc-body.dark-mode .field__wrap-date .MuiIconButton-root {
  background: #272B30 !important;
  color: #EFEFEF !important;
}
.field__wrap-date .MuiIconButton-root .MuiSvgIcon-root {
  height: 21px;
  width: 21px;
}
.field__wrap-date .MuiIconButton-root .MuiIconButton-root .MuiTouchRipple-root {
  display: none;
}
body.kyc-body.dark-mode .field__wrap-date .MuiIconButton-label {
  color: #ffffff;
}

.field__wrap-date-white .MuiInputBase-root .MuiInputBase-root {
  background: #ffffff;
  border-color: rgba(239, 239, 239, 0.35);
}
body.kyc-body.dark-mode .field__wrap-date-white .MuiInputBase-root .MuiInputBase-root {
  background: #111315 !important;
  border-color: rgba(17, 19, 21, 0.5) !important;
}
.field__wrap-date-white .MuiInputBase-root .MuiInputBase-root.Mui-focused {
  border-color: #9A9FA5;
}
body.kyc-body.dark-mode .field__wrap-date-white .MuiInputBase-root .MuiInputBase-root.Mui-focused {
  border-color: #33383F !important;
}

.field__wrap-date.field__wrap-date-nomaterial fieldset {
  display: none !important;
}
.field__wrap-date.field__wrap-date-nomaterial .MuiInputBase-root {
  padding-left: 0;
  padding-right: 0;
}

body.kyc-body.dark-mode .page__inner-onboarding .field__wrap-date .MuiInputBase-root {
  border-color: #272B30 !important;
  background-color: #111315 !important;
}
body.kyc-body.dark-mode .page__inner-onboarding .field__wrap-date .MuiInputBase-root.Mui-focused {
  border-color: #33383F !important;
}

.field__wrap-radio .MuiFormControlLabel-root {
  margin-right: 0;
  margin-left: 0;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.field__wrap-radio .MuiFormControlLabel-root .MuiFormControlLabel-label {
  padding-left: 12px;
  font-family: "Inter", sans-serif !important;
  font-size: 15px;
  font-weight: 500;
  -webkit-transition: all .2s;
  transition: all .2s;
  line-height: 1.6;
}
.field__wrap-radio .MuiFormControlLabel-root .MuiRadio-root {
  padding: 0;
}
.field__wrap-radio .MuiFormControlLabel-root .MuiSvgIcon-root {
  display: none;
}
.field__wrap-radio .MuiFormControlLabel-root .MuiIconButton-label {
  position: relative;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(111, 118, 126, 0.4);
  -webkit-transition: all .2s;
  transition: all .2s;
  top: -4px;
}
body.kyc-body.dark-mode .field__wrap-radio .MuiFormControlLabel-root .MuiIconButton-label {
  border-color: #6F767E;
}
.field__wrap-radio .MuiFormControlLabel-root .MuiIconButton-label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #FCFCFC;
  -webkit-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25), inset 0px -1px 2px #dedede;
          box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25), inset 0px -1px 2px #dedede;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}
body.kyc-body.dark-mode .field__wrap-radio .MuiFormControlLabel-root .MuiIconButton-label::before {
  background: #272B30;
}
.field__wrap-radio .MuiRadio-root.Mui-checked .MuiIconButton-label {
  background: #2A85FF;
  border-color: #2A85FF;
}
.field__wrap-radio .MuiRadio-root.Mui-checked .MuiIconButton-label::before {
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}

.field-radio__material .MuiFormControlLabel-root + .MuiFormControlLabel-root {
  margin-left: 16px;
}

.field__wrap-radio_text_gray .MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: #6F767E;
}
.field__wrap-radio_text_gray .MuiRadio-root.Mui-checked + .MuiFormControlLabel-label {
  color: #1A1D1F;
}
body.kyc-body.dark-mode .field__wrap-radio_text_gray .MuiRadio-root.Mui-checked + .MuiFormControlLabel-label {
  color: #FCFCFC;
}

.field__wrap-radio_vertical .MuiFormControlLabel-root + .MuiFormControlLabel-root {
  margin-top: 16px;
}

.field__text-gray .radio__text {
  padding-left: 12px;
  font-family: "Inter", sans-serif !important;
  font-size: 15px;
  font-weight: 600;
  color: #6F767E;
  -webkit-transition: all .2s;
  transition: all .2s;
  line-height: 1.6;
}
.field__text-gray .radio__input:checked + .radio__inner .radio__text {
  color: #1A1D1F;
}
body.kyc-body.dark-mode .field__text-gray .radio__input:checked + .radio__inner .radio__text {
  color: #FCFCFC;
}

@media only screen and (max-width: 639px) {
  .field__radio-group_horizontal {
    width: 100%;
  }
}
.field__radio-group_horizontal + .field__radio-group_horizontal {
  margin-left: 16px;
}
@media only screen and (max-width: 639px) {
  .field__radio-group_horizontal + .field__radio-group_horizontal {
    margin-left: 0;
    margin-top: 16px;
  }
}

.field__radio-group_vertical .radio__tick {
  top: 4px;
}
.field__radio-group_vertical + .field__radio-group_vertical {
  margin-top: 16px;
}

.field__wrap-radio-500 .radio__text {
  font-weight: 500;
}

.field__wrap-radio-full > * {
  width: 100%;
}
.field__wrap-radio-full .radio__text {
  padding-left: 12px;
  font-weight: 500;
}

.field__wrap-radio-cleartop .field__radio-group_vertical .radio__tick {
  top: 0;
}

/* Field radio style 1 */
.field__inner-divide.field.field__radio-kyc-style-1 .MuiFormControlLabel-root:last-child {
  margin-right: 0;
}

.field__radio-kyc-style-1 .MuiButtonBase-root {
  padding-top: 0;
  padding-bottom: 0;
}
.field__radio-kyc-style-1 .MuiButtonBase-root.Mui-checked .MuiSvgIcon-root {
  fill: #2A85FF;
}
.field__radio-kyc-style-1 .MuiTypography-root {
  font-family: "Inter", sans-serif;
  font-size: 14px;
}
.field__radio-kyc-style-1 .MuiSvgIcon-root {
  fill: rgba(111, 118, 126, 0.4);
}

/* Field radio & checkbox - style 1 1 */
.field__radio-kyc-style-1.field__radio-kyc-style-1_1 .MuiFormControlLabel-root .MuiTypography-root {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
  color: #6F767E;
}

.field__radio-kyc-style-1_1 .field__wrap .MuiFormGroup-root {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}
.field__radio-kyc-style-1_1 .field__wrap .checkbox {
  margin-right: 16px;
}

.field__radio-kyc-style-1 .circle-bullet {
  list-style-type: disc;
  padding-left: 16px;
  padding-top: 8px;
}

.field__radio-kyc__style-1_4 .circle-bullet {
  list-style-type: disc;
  padding-left: 16px;
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.6;
  color: #6F767E;
}

.field__radio-kyc__style-1_4 li + li {
  margin-top: 6px;
}

.field.field__radio-kyc__style-1_2 .checkbox {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  max-width: calc(50% - 16px);
  width: calc(50% - 16px);
  margin: 0 8px 16px;
}

.field.field__radio-kyc__style-1_3 .MuiFormControlLabel-root .MuiTypography-root {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.71429;
  color: #33383F;
}
body.kyc-body.dark-mode .field.field__radio-kyc__style-1_3 .MuiFormControlLabel-root .MuiTypography-root {
  color: #FCFCFC;
}

.field.field__radio-kyc__style-1_3 .MuiFormControlLabel-root + .MuiFormControlLabel-root {
  margin-top: 16px;
}

.field.field__radio-kyc__style-1_4 .MuiFormControlLabel-root .MuiTypography-root,
.field.field__radio-kyc__style-1_4 .checkbox__text {
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: #6F767E;
}

.field.field__radio-kyc__style-1_4 .MuiFormControlLabel-root + .MuiFormControlLabel-root,
.field.field__radio-kyc__style-1_4 .checkbox + .checkbox {
  margin-top: 16px;
}

.field.field__radio-kyc__style-1_4 .checkbox__inner {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.field__radio-kyc__style-1_4 .MuiSvgIcon-root {
  fill: rgba(111, 118, 126, 0.4);
}

.field__radio-kyc__style-1_4 .MuiButtonBase-root.Mui-checked .MuiSvgIcon-root {
  fill: #2A85FF;
}

.field__radio-kyc__style-1_5 .MuiFormGroup-root {
  margin: -32px -12px -0;
}
@media only screen and (max-width: 639px) {
  .field__radio-kyc__style-1_5 .MuiFormGroup-root {
    display: block;
    margin: 0;
  }
}
.field__radio-kyc__style-1_5 .MuiFormControlLabel-root {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(50% - 24px);
          flex: 0 0 calc(50% - 24px);
  width: calc(50% - 24px);
  margin: 32px 12px 0;
}
@media only screen and (max-width: 639px) {
  .field__radio-kyc__style-1_5 .MuiFormControlLabel-root {
    width: 100%;
    margin: 24px 0 0;
  }
}
.field__radio-kyc__style-1_5 .MuiIconButton-root {
  padding-left: 0;
}
.field__radio-kyc__style-1_5 .MuiFormControlLabel-label {
  font-weight: 500 !important;
}
.field__radio-kyc__style-1_5 .Mui-checked + .MuiFormControlLabel-label {
  color: #1A1D1F !important;
}
body.kyc-body.dark-mode .field__radio-kyc__style-1_5 .Mui-checked + .MuiFormControlLabel-label {
  color: #FCFCFC !important;
}
.field__radio-kyc__style-1_5 .circle-bullet {
  padding-left: 24px;
}

.field__radio-kyc__style-1_6 .MuiFormControlLabel-root {
  margin-right: 0;
  margin-left: 0;
}
.field__radio-kyc__style-1_6 .MuiFormControlLabel-root + .MuiFormControlLabel-root {
  margin-top: 12px;
}
.field__radio-kyc__style-1_6 .MuiFormControlLabel-label {
  font-weight: 500 !important;
}
.field__radio-kyc__style-1_6 .Mui-checked + .MuiFormControlLabel-label {
  color: #1A1D1F !important;
}
body.kyc-body.dark-mode .field__radio-kyc__style-1_6 .Mui-checked + .MuiFormControlLabel-label {
  color: #FCFCFC !important;
}
.field__radio-kyc__style-1_6 .circle-bullet {
  padding-left: 24px;
}

/* Field Radio disabled */
.field__radio-kyc-style-1 .Mui-disabled {
  cursor: not-allowed;
}

.field__radio-kyc-style-1 .Mui-disabled .MuiButtonBase-root.Mui-checked .MuiSvgIcon-root,
.field__radio-kyc-style-1 .Mui-disabled .MuiSvgIcon-root {
  fill: rgba(111, 118, 126, 0.4);
}

.field__radio-kyc-style-1.field__radio-kyc-style-1_1 .Mui-disabled .MuiFormControlLabel-root .MuiTypography-root {
  color: #6F767E;
}

body.kyc-body.dark-mode .radio__input:checked + .radio__inner .radio__tick::before {
  background-color: #111315;
  -webkit-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25), inset 0px -1px 2px #dedede;
          box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25), inset 0px -1px 2px #dedede;
}

.field__wrap-textarea .MuiFormControl-root {
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
}
.field__wrap-textarea .MuiTextField-root .MuiInput-underline, .field__wrap-textarea .MuiOutlinedInput-multiline, .field__wrap-textarea .MuiInputBase-multiline {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
  color: #1A1D1F;
  -webkit-transition: all .2s;
  transition: all .2s;
  border-radius: 12px;
  background: #F4F4F4;
  border: 2px solid #F4F4F4;
  padding: 24px;
}
body.kyc-body.dark-mode .field__wrap-textarea .MuiTextField-root .MuiInput-underline,
body.kyc-body.dark-mode .field__wrap-textarea .MuiOutlinedInput-multiline,
body.kyc-body.dark-mode .field__wrap-textarea .MuiInputBase-multiline {
  color: #FCFCFC !important;
  border-color: #111315 !important;
}
.field__wrap-textarea .MuiTextField-root .MuiInput-underline {
  width: 100%;
  padding: 0;
  border-radius: 12px;
  border: 2px solid transparent;
  background: #F4F4F4;
  font-family: 'Inter', sans-serif !important;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
  color: #1A1D1F;
  -webkit-transition: all .2s;
  transition: all .2s;
}
body.kyc-body.dark-mode .field__wrap-textarea .MuiTextField-root .MuiInput-underline {
  color: #FCFCFC;
}
.field__wrap-textarea .MuiTextField-root .MuiInput-underline .MuiInputBase-input {
  padding: 0;
}
.field__wrap-textarea .MuiInput-underline:after,
.field__wrap-textarea .MuiInput-underline:before,
.field__wrap-textarea .MuiInputBase-root fieldset {
  display: none;
}
.field__wrap-textarea textarea {
  padding: 16px 10px !important;
}

body.kyc-body.dark-mode .field__wrap-textarea .MuiOutlinedInput-multiline,
body.kyc-body.dark-mode .field__wrap-textarea .MuiInputBase-multiline {
  background-color: #272B30 !important;
  border-color: #272B30 !important;
}
.field__wrap-textarea .MuiOutlinedInput-multiline.Mui-focused, .field__wrap-textarea .MuiInputBase-multiline.Mui-focused {
  background: #ffffff;
  border-color: #9A9FA5 !important;
}
body.kyc-body.dark-mode .field__wrap-textarea .MuiOutlinedInput-multiline.Mui-focused, body.kyc-body.dark-mode .field__wrap-textarea .MuiInputBase-multiline.Mui-focused {
  background: #111315;
  border-color: #33383F !important;
}

body.kyc-body.dark-mode .page__inner-onboarding .field__wrap-textarea .MuiTextField-root .MuiInput-underline {
  border-color: #272B30 !important;
  background-color: #111315 !important;
}
body.kyc-body.dark-mode .page__inner-onboarding .field__wrap-textarea .MuiTextField-root .MuiInput-underline.Mui-focused {
  border-color: #33383F !important;
}

/* Field Select */
.field__wrap-select {
  position: relative;
}
.field__wrap-select .MuiSelect-icon,
.field__wrap-select .MuiInput-underline:after,
.field__wrap-select .MuiInput-underline:before,
.field__wrap-select fieldset {
  display: none;
}
.field__wrap-select .MuiFormControl-root {
  width: 100%;
}
.field__wrap-select .MuiInputBase-root {
  width: 100%;
  height: 48px;
  padding: 0;
  border-radius: 12px;
  border: 2px solid transparent;
  background: #F4F4F4;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
  color: #1A1D1F;
  -webkit-transition: all .2s;
  transition: all .2s;
}
body.kyc-body.dark-mode .field__wrap-select .MuiInputBase-root {
  background: #111315 !important;
  color: #FCFCFC !important;
}
.field__wrap-select.field__wrap-select-white .MuiInputBase-root {
  background: #ffffff;
}
body.kyc-body.dark-mode .field__wrap-select.field__wrap-select-white .MuiInputBase-root {
  background: #111315 !important;
}
.field__wrap-select.field__wrap-select-white .MuiInputBase-root.Mui-focused {
  border-color: #9A9FA5;
}
body.kyc-body.dark-mode .field__wrap-select.field__wrap-select-white .MuiInputBase-root.Mui-focused {
  border-color: #33383F !important;
}
.field__wrap-select .MuiSelect-root {
  padding: 0 10px;
  padding-right: 24px;
  position: relative;
}
.field__wrap-select .MuiSelect-root:focus {
  background: transparent;
}
.field__wrap-select .MuiSelect-root::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0px;
  bottom: 0;
  width: 48px;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12.707 15.707l-.094.083a1 1 0 0 1-1.32-.083h0l-6-6-.083-.094a1 1 0 0 1 1.497-1.32h0L12 13.585l5.293-5.292.094-.083a1 1 0 0 1 1.32 1.497h0l-6 6z' fill='%236f767e'/%3E%3C/svg%3E") no-repeat 50% 50%/24px auto;
}
.field__wrap-select .MuiSelect-root[aria-expanded="true"]::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.MuiMenu-paper.MuiPopover-paper {
  padding: 8px 0;
  border-radius: 12px;
  background: #ffffff !important;
  border: none;
  -webkit-box-shadow: inset 0 0 0 2px #EFEFEF, 0 4px 12px rgba(244, 244, 244, 0.1) !important;
          box-shadow: inset 0 0 0 2px #EFEFEF, 0 4px 12px rgba(244, 244, 244, 0.1) !important;
}
body.kyc-body.dark-mode .MuiMenu-paper.MuiPopover-paper {
  background: #111315 !important;
  -webkit-box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.4), 0 4px 12px rgba(255, 255, 255, 0.11) !important;
          box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.4), 0 4px 12px rgba(255, 255, 255, 0.11) !important;
}
.MuiMenu-paper .MuiMenu-list {
  padding: 0;
}
.MuiMenu-paper .MuiListItem-root {
  min-height: auto;
  padding: 8px 16px;
  background: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71429;
  color: #6F767E;
  font-family: "Inter", sans-serif;
  -webkit-transition: all .2s;
  transition: all .2s;
}
.MuiMenu-paper .MuiListItem-root:hover, .MuiMenu-paper .MuiListItem-root:focus {
  background-color: transparent;
  font-weight: 600;
  color: #1A1D1F;
}
body.kyc-body.dark-mode .MuiMenu-paper .MuiListItem-root:hover, body.kyc-body.dark-mode .MuiMenu-paper .MuiListItem-root:focus {
  color: #FCFCFC !important;
}
.MuiMenu-paper .MuiListItem-root.Mui-selected {
  background-color: transparent !important;
  font-weight: 600;
  color: #2A85FF !important;
}

body.kyc-body.dark-mode .settings__fieldset .field__wrap-select .MuiInputBase-root {
  background: #272B30 !important;
}

body.kyc-body.dark-mode .page__inner-onboarding .settings__fieldset .field__wrap-select .MuiInputBase-root {
  border-color: #272B30 !important;
  background-color: #111315 !important;
}

/* old will remove */
/* .field__wrap-select .dropdown__head{
  position: relative;
  height: 48px;
  padding: 0 48px 0 12px;
  border-radius: 12px;
  box-shadow: inset 0 0 0 2px #efefef;
  font-size: 14px;
  font-weight: 600;
  line-height: 48px;
  background-color: white;
  color: #1A1D1F;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  user-select: none;
  transition: all .2s;
} */
/* .field__wrap-select .field__remove_shadow.dropdown__head{
  box-shadow: none;
} */
/* .field__wrap-select .dropdown__head::before{
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 48px;
  transition: transform .2s;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12.707 15.707l-.094.083a1 1 0 0 1-1.32-.083h0l-6-6-.083-.094a1 1 0 0 1 1.497-1.32h0L12 13.585l5.293-5.292.094-.083a1 1 0 0 1 1.32 1.497h0l-6 6z' fill='%236f767e'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto;
} */
/* .field__wrap-select .dropdown__head.field__gray{
  background: #F4F4F4;
  box-shadow: none;
} */
/* .field__wrap-select .dropdown__selection{
  overflow: hidden;
  t-overflow: ellipsis;
  white-space: nowrap;
} */
/* .field__wrap-select .dropdown__body{
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  right: 0;
  padding: 8px 0;
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: inset 0 0 0 2px #efefef, 0 4px 12px rgb(244 244 244 / 10%);
  visibility: hidden;
  opacity: 0;
  transform: translateY(3px);
  transition: all .2s;
  z-index: 4;
} */
/* .field__wrap-select .dropdown__option{
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71429;
  color: #6F767E;
  transition: color .2s;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
} */
/* .field__wrap-select .dropdown__option.dropdown__option-uppercase{
  t-transform: uppercase;
} */
/* .field__wrap-select .dropdown__option:hover{
  color: #1A1D1F;
} */
/* .field__wrap-select .dropdown__option.selectioned{
  color: #2A85FF !important;
} */
/* .dropdown__active .field__wrap-select .dropdown__head::before{
  transform: rotate(180deg);
} */
/* .dropdown__active .dropdown__body{
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
} */
/* .dropdown__active .field__wrap-select .dropdown__head.field__gray{
  background: #fff;
  box-shadow: inset 0 0 0 2px #9A9FA5;
} */
/* .field__wrap-select .MuiFormControl-root{
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
} */
/* .field__wrap-select .MuiInputBase-root{
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
  color: #1A1D1F;
  transition: all .2s;
  border-radius: 12px;
} */
/* .field__wrap-select .MuiInput-underline:before,
.field__wrap-select .MuiInput-underline:after,
.field__wrap-select .MuiInputBase-root fieldset{
  display: none;
} */
/* .field__wrap-select .MuiSelect-selectMenu{
  width: 100%;
  height: 44px;
  border-radius: 12px;
  border: 2px solid #EFEFEF;
} */
/* .field__wrap-select-white .MuiSelect-selectMenu{
  background: white;
  border-color: white;
  border-radius: 12px;
} */
/* .field__wrap-select-white .MuiInputBase-root.Mui-focused .MuiSelect-selectMenu{
  border-color: #9A9FA5;
} */
/* .field__wrap-select .MuiSelect-select:focus{
  background: #F4F4F4;
} */
/* .field__wrap-select .MuitField-root .MuiOutlinedInput-input,
.field__wrap-select .MuiSelect-selectMenu{
  padding: 0 10px;
  min-height: initial;
  line-height: 44px;
  border-radius: 12px;
} */
/* Image Upload */
.file .summary__title {
  margin: 16px auto 16px;
  font-weight: 500;
  color: #6F767E;
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
}

.field__wrap-imageupload-white .fileContainer {
  background: #ffffff;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 12px;
  text-align: center;
}
body.kyc-body.dark-mode .field__wrap-imageupload-white .fileContainer {
  background: #111315;
}

.field-upload__preview {
  cursor: default;
}
.field-upload__button {
  pointer-events: initial;
  cursor: pointer;
}
.field-upload__button:hover, .field-upload__button:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

/* Field OTP */
@media only screen and (max-width: 767px) {
  .settings__row-otp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.settings__row-otp .field {
  -ms-flex-preferred-size: calc(25% - 16px);
      flex-basis: calc(25% - 16px);
  width: calc(25% - 16px);
}
@media only screen and (max-width: 767px) {
  .settings__row-otp .field {
    margin-bottom: 12px;
  }
}

/* Field Phone */
.field__wrap-phone .react-tel-input .country-list {
  top: 100%;
  left: 0;
  margin-top: 0;
  border-radius: 12px;
}
body.kyc-body.dark-mode .field__wrap-phone .react-tel-input .country-list {
  background: #111315;
}
.field__wrap-phone .react-tel-input .country-list .country.highlight, .field__wrap-phone .react-tel-input .country-list .country:hover, .field__wrap-phone .react-tel-input .country-list .country:focus {
  background: #272B30;
}
.field__wrap-phone .react-tel-input .form-control {
  width: 100%;
  height: 48px;
  padding: 0 10px;
  border-radius: 12px;
  border: 2px solid #F4F4F4;
  padding-left: 58px;
  background: #F4F4F4;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
  color: #1A1D1F;
  -webkit-transition: all .2s;
  transition: all .2s;
}
body.kyc-body.dark-mode .field__wrap-phone .react-tel-input .form-control {
  border-color: #272B30;
  background: #272B30;
  color: #FCFCFC;
}
.field__wrap-phone .react-tel-input .form-control.focus-visible, .field__wrap-phone .react-tel-input .form-control:focus {
  border-color: #9A9FA5;
  background-color: #ffffff;
}
body.kyc-body.dark-mode .field__wrap-phone .react-tel-input .form-control.focus-visible, body.kyc-body.dark-mode .field__wrap-phone .react-tel-input .form-control:focus {
  border-color: #33383F;
  background-color: #111315;
}
body.kyc-body.dark-mode .field__wrap-phone .react-tel-input .form-control.focus-visible ~ .flag-dropdown, body.kyc-body.dark-mode .field__wrap-phone .react-tel-input .form-control:focus ~ .flag-dropdown {
  background: #111315;
}
body.kyc-body.dark-mode .field__wrap-phone .react-tel-input .form-control.focus-visible ~ .flag-dropdown .selected-flag, body.kyc-body.dark-mode .field__wrap-phone .react-tel-input .form-control:focus ~ .flag-dropdown .selected-flag {
  background: #111315;
}
.field__wrap-phone .react-tel-input .flag-dropdown {
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 46px;
  padding-top: 1px;
  border-radius: 10px 0 0 10px;
  background: #F4F4F4;
  color: #1A1D1F;
  border: none;
}
body.kyc-body.dark-mode .field__wrap-phone .react-tel-input .flag-dropdown {
  background: #272B30;
  color: #FCFCFC;
}
.field__wrap-phone .react-tel-input .flag-dropdown.open, .field__wrap-phone .react-tel-input .flag-dropdown.open .selected-flag, .field__wrap-phone .react-tel-input .selected-flag, .field__wrap-phone .react-tel-input .selected-flag:hover, .field__wrap-phone .react-tel-input .selected-flag:focus {
  background: #F4F4F4;
  border-radius: 0;
}
body.kyc-body.dark-mode .field__wrap-phone .react-tel-input .flag-dropdown.open,
body.kyc-body.dark-mode .field__wrap-phone .react-tel-input .flag-dropdown.open .selected-flag,
body.kyc-body.dark-mode .field__wrap-phone .react-tel-input .selected-flag,
body.kyc-body.dark-mode .field__wrap-phone .react-tel-input .selected-flag:hover,
body.kyc-body.dark-mode .field__wrap-phone .react-tel-input .selected-flag:focus {
  background: #272B30;
}

.field__checkbox-kyc .checkbox__text {
  padding-left: 8px;
}

.checkbox input[type="checkbox"]:disabled + .checkbox__inner:hover .checkbox__tick, .checkbox input[type="checkbox"]:disabled + .checkbox__inner .checkbox__tick, .checkbox input[type="checkbox"]:checked:disabled + .checkbox__inner:hover .checkbox__tick, .checkbox input[type="checkbox"]:checked:disabled + .checkbox__inner .checkbox__tick, .switch input[type="checkbox"]:disabled + .switch__inner:hover .switch__box, .switch input[type="checkbox"]:disabled + .switch__inner .switch__box, .switch input[type="checkbox"]:checked:disabled + .switch__inner:hover .switch__box, .switch input[type="checkbox"]:checked:disabled + .switch__inner .switch__box {
  border-color: rgba(111, 118, 126, 0.4);
  cursor: not-allowed;
}
body.kyc-body.dark-mode .checkbox input[type="checkbox"]:disabled + .checkbox__inner:hover .checkbox__tick,
body.kyc-body.dark-mode .checkbox input[type="checkbox"]:disabled + .checkbox__inner .checkbox__tick,
body.kyc-body.dark-mode .checkbox input[type="checkbox"]:checked:disabled + .checkbox__inner:hover .checkbox__tick,
body.kyc-body.dark-mode .checkbox input[type="checkbox"]:checked:disabled + .checkbox__inner .checkbox__tick,
body.kyc-body.dark-mode .switch input[type="checkbox"]:disabled + .switch__inner:hover .switch__box,
body.kyc-body.dark-mode .switch input[type="checkbox"]:disabled + .switch__inner .switch__box,
body.kyc-body.dark-mode .switch input[type="checkbox"]:checked:disabled + .switch__inner:hover .switch__box,
body.kyc-body.dark-mode .switch input[type="checkbox"]:checked:disabled + .switch__inner .switch__box {
  border-color: #6F767E;
}

.checkbox input[type="checkbox"]:checked:disabled + .checkbox__inner:hover .checkbox__tick,
.checkbox input[type="checkbox"]:checked:disabled + .checkbox__inner .checkbox__tick,
.switch input[type="checkbox"]:checked:disabled + .switch__inner:hover .switch__box,
.switch input[type="checkbox"]:checked:disabled + .switch__inner .switch__box {
  background-color: #6F767E;
}

.checkbox input[type="checkbox"]:disabled + .checkbox__inner:hover .checkbox__text,
.checkbox input[type="checkbox"]:disabled + .checkbox__inner .checkbox__text,
.checkbox input[type="checkbox"]:checked:disabled + .checkbox__inner:hover .checkbox__text,
.checkbox input[type="checkbox"]:checked:disabled + .checkbox__inner .checkbox__text,
.switch input[type="checkbox"]:checked:disabled + .switch__inner:hover .switch__box,
.switch input[type="checkbox"]:checked:disabled + .switch__inner .switch__box {
  color: #6F767E;
  cursor: not-allowed;
}

.checkbox__wrapper {
  margin-bottom: -16px;
}
.checkbox__wrapper .checkbox {
  margin-right: 16px;
  margin-bottom: 16px;
}
.checkbox__wrapper.checkbox__wrapper_clear {
  margin-bottom: 0;
}
.checkbox__wrapper.checkbox__wrapper_clear.checkbox {
  margin-bottom: 0;
}

/* Switch */
.create__line-kyc .switch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-flow: row-reverse;
          flex-flow: row-reverse;
  width: 100%;
}
.create__line-kyc .switch .switch__inner {
  margin-left: 16px;
}
.create__line-kyc.create__line_left .switch__box {
  top: 2px;
}
.create__line-kyc.create__line_left .switch {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
}
.create__line-kyc.create__line_left .switch .switch__inner {
  margin-left: 0;
  margin-right: 16px;
}

.create__line.remove__line {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.create__line.remove__line + .remove__line {
  margin-top: 16px;
}

.create__line-gray .create__info {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #6F767E;
  -webkit-transition: all .2s;
  transition: all .2s;
  line-height: 1.6;
}
.create__line-gray.create__line-kyc .switch__input:checked ~ .create__info {
  color: #1A1D1F;
}
body.kyc-body.dark-mode .create__line-gray.create__line-kyc .switch__input:checked ~ .create__info {
  color: #FCFCFC;
}

.settings .create__line-kyc .switch {
  margin-left: 0;
  width: 100%;
}

@media only screen and (max-width: 1558px) {
  .create__list_logevent .checkbox {
    -ms-flex-preferred-size: calc(50% - 16px);
        flex-basis: calc(50% - 16px);
    width: calc(50% - 16px);
  }
}
@media only screen and (max-width: 1023px) {
  .create__list_logevent .checkbox {
    -ms-flex-preferred-size: calc(100% - 16px);
        flex-basis: calc(100% - 16px);
    width: calc(100% - 16px);
  }
}
@media only screen and (max-width: 639px) {
  .create__list_logevent .checkbox__text {
    font-size: 13px;
  }
}

.field-iframe {
  border-radius: 12px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  /* padding-bottom: calc(4/7*100%); */
  width: 100%;
  height: 300px;
}

.field-iframe .map-container {
  height: 100%;
  width: 100%;
}

.field-iframe iframe {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
}

/* Buttons */
.data__center-open,
.data__center-remove {
  -webkit-transition: all .2s;
  transition: all .2s;
}

.products__nav_btn button + button {
  margin-left: 6px;
}

.data__center-open:hover,
.data__center-open:focus {
  background: #90e2b5;
}

.button-danger,
.data__center-remove {
  background: #FF6A55;
}

.button-danger:hover,
.button-danger:focus,
.data__center-remove:hover,
.data__center-remove:focus {
  background: #f7553e;
}

.button-success {
  background: #90e2b5;
  color: #1A1D1F;
}
body.kyc-body.dark-mode .button-success {
  color: #FCFCFC;
}

.form__button_fixing .icon {
  fill: #6F767E !important;
}

.button-stroke__white {
  color: #ffffff;
}
body.kyc-body.dark-mode .button-stroke__white {
  color: #111315;
}

.button-stroke__white:hover, .button-stroke__white:focus {
  color: #1A1D1F;
}
body.kyc-body.dark-mode .button-stroke__white:hover,
body.kyc-body.dark-mode .button-stroke__white:focus {
  color: #111315;
}

.button__full {
  width: 100%;
}

button:disabled {
  cursor: not-allowed !important;
  color: #FF6A55 !important;
  background: none !important;
  -webkit-box-shadow: 0 0 0 2px #EFEFEF inset !important;
          box-shadow: 0 0 0 2px #EFEFEF inset !important;
}
body.kyc-body.dark-mode button:disabled {
  -webkit-box-shadow: 0 0 0 2px #272B30 inset !important;
          box-shadow: 0 0 0 2px #272B30 inset !important;
}

.button-clear__height {
  height: initial;
  padding-top: 8px;
  padding-bottom: 8px;
}

.onboarding-sidebar__fieldset button:disabled {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #6F767E !important;
}
.onboarding-sidebar__fieldset button:disabled:hover svg, .onboarding-sidebar__fieldset button:disabled:focus svg, .onboarding-sidebar__fieldset button:disabled.active svg {
  fill: #6F767E;
}

.application-answer__btns .button {
  background-color: #2A85FF;
}
body.kyc-body.dark-mode .application-answer__btns .button {
  background-color: #2A85FF;
}
.application-answer__btns .button:hover, .application-answer__btns .button:focus {
  background-color: #0069f6;
}
body.kyc-body.dark-mode .application-answer__btns .button:hover, body.kyc-body.dark-mode .application-answer__btns .button:focus {
  background-color: #0069f6;
}

.media__btns-tab {
  margin: 0 0 -12px;
}

.media__btns-tab button {
  margin-right: 12px;
  margin-bottom: 12px;
  min-width: 128px;
}

.media__btns-tab button.active {
  color: #ffffff;
}
body.kyc-body.dark-mode .media__btns-tab button.active {
  color: #111315;
}

.products__tab.js-tabs-item.active {
  display: block;
}

body.kyc-body.dark-mode .transactions__table .transactions__row:first-child .transactions__col {
  color: #ffffff;
}

.transactions__col.transactions__col-full {
  background: transparent !important;
  height: 480px;
}

.products__col .products__status {
  margin-bottom: 4px;
  margin-right: 4px;
}

.products__col.products__col-first-clear {
  width: initial;
  font-size: 14px;
}
@media only screen and (max-width: 1023px) {
  .products__col.products__col-first-clear {
    display: block;
    margin-bottom: 12px;
  }
}

.products__col.products__col-pseudo-clear::after {
  display: none;
}

.products__item-chip > * {
  margin-right: 6px;
}

.products__item.products__item-normal:hover, .products__item.products__item-normal:focus {
  color: #1A1D1F;
}
body.kyc-body.dark-mode .products__item.products__item-normal:hover,
body.kyc-body.dark-mode .products__item.products__item-normal:focus {
  color: #FCFCFC;
}

.products__table-logevent,
.products__table-userlist {
  font-size: 14px;
}

.products__table-kyclist__wrapper {
  overflow-x: auto;
}

.products__table-userlist .products__row .products__col:nth-child(4) {
  max-width: 120px;
}

.products__table-userlist .products__col-have_button .MuiButtonBase-root {
  background-color: #ffffff;
  color: #1A1D1F;
}
body.kyc-body.dark-mode .products__table-userlist .products__col-have_button .MuiButtonBase-root {
  color: #FCFCFC;
}

.products__table-logevent .products__item-first {
  font-size: 14px;
}

.col__180 {
  min-width: 180px;
}

.col__250 {
  min-width: 250px;
}

.products__col-kyc-action,
.transactions__col-action {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.products__col-kyc-action .actions__button,
.transactions__col-action .actions__button {
  background-color: #EFEFEF;
}

.row__force {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid #EFEFEF;
}
@media only screen and (max-width: 1023px) {
  .row__force {
    padding: 0;
  }
}
body.kyc-body.dark-mode .row__force {
  border-color: #272B30;
}
.row__force + .row__force {
  margin-top: 24px;
}
.row__force .transactions__label {
  display: block;
  margin-bottom: 12px;
}
.row__force .transactions__details {
  display: block;
  margin-right: auto;
  padding-right: 16px;
}
.row__force .transactions__col {
  padding: 12px 24px 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.row__force .transactions__col:first-child {
  padding-top: 12px;
}
.row__force .transactions__col:last-child {
  padding-bottom: 24px;
}
.row__force .transactions__col:nth-child(2n), .row__force .transactions__col:nth-child(1) {
  background-color: #ffffff;
}
body.kyc-body.dark-mode .row__force .transactions__col:nth-child(2n), body.kyc-body.dark-mode .row__force .transactions__col:nth-child(1) {
  background-color: #111315;
}
.row__force .transactions__col:nth-child(1) {
  border-bottom: 1px solid #EFEFEF;
}
body.kyc-body.dark-mode .row__force .transactions__col:nth-child(1) {
  border-bottom: 1px solid #272B30;
}
.row__force .transactions__col:nth-child(2) {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding-top: 24px;
  padding-bottom: 12px;
  background-color: #ffffff;
}
body.kyc-body.dark-mode .row__force .transactions__col:nth-child(2) {
  background-color: #111315;
}

@media only screen and (max-width: 478px) {
  .kyc__table_transactions .transactions__col,
  .row__force_rwd .transactions__col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    overflow: hidden;
  }
}
@media only screen and (max-width: 478px) {
  .kyc__table_transactions .transactions__col-action,
  .row__force_rwd .transactions__col-action {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: end;
  }
}
.kyc__table_transactions .transactions__product + .transactions__product,
.row__force_rwd .transactions__product + .transactions__product {
  margin-top: 8px;
}
@media only screen and (max-width: 1023px) {
  .kyc__table_transactions .transactions__product_wrapper,
  .row__force_rwd .transactions__product_wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
}
@media only screen and (max-width: 478px) {
  .kyc__table_transactions .transactions__product_wrapper,
  .row__force_rwd .transactions__product_wrapper {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

@media only screen and (max-width: 1023px) {
  .kyc__table_transactions_clear-col-3 .transactions__col:nth-child(3) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (max-width: 1023px) {
  .transactions__table-order-v2 .transactions__row .transactions__col:nth-child(1) {
    -webkit-box-ordinal-group: -3;
        -ms-flex-order: -4;
            order: -4;
  }
}

.transactions__table-admin_address .transactions__col:nth-child(1) {
  -webkit-box-ordinal-group: -1;
      -ms-flex-order: -2;
          order: -2;
}

.transactions__col-v2 {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.transactions__col-v2 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}

.products__table-userlist .products__status {
  margin-right: 4px;
  margin-bottom: 4px;
}
.products__table-userlist .customer__user {
  max-width: initial;
}
@media only screen and (max-width: 478px) {
  .products__table-userlist .transactions__col {
    display: block;
  }
}
@media only screen and (max-width: 478px) {
  .products__table-userlist .transactions__col .transactions__label {
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 478px) {
  .products__table-adminvideoident .products__col, .products__table-fix_label_rwd .products__col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
@media only screen and (max-width: 478px) {
  .products__table-adminvideoident .products__col .products__label, .products__table-fix_label_rwd .products__col .products__label {
    margin-bottom: 4px;
    width: 100%;
    padding-right: 0;
  }
}
@media only screen and (max-width: 1023px) {
  .products__table-fix_firstcolumn .products__row .products__col:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 1023px) {
  .products__foot {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
  }
}
.products__foot-pagination {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
@media only screen and (max-width: 1023px) {
  .products__foot-pagination {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
    margin-bottom: 16px;
  }
}
body.kyc-body.dark-mode .products__foot-pagination button:disabled {
  -webkit-box-shadow: 0 0 0 2px #6F767E inset !important;
          box-shadow: 0 0 0 2px #6F767E inset !important;
}
.products__foot-pagination button:disabled svg {
  fill: #6F767E;
}
body.kyc-body.dark-mode .products__foot-pagination .kyc__pagination-arrow {
  color: #6F767E;
}
body.kyc-body.dark-mode .products__foot-pagination .kyc__pagination-arrow.products__arrow-active {
  color: #ffffff;
}
@media only screen and (max-width: 1023px) {
  .products__foot .field {
    display: block;
  }
}
.products__foot .field__label {
  font-size: 13px;
  margin-bottom: 0;
  margin-right: 16px;
}
@media only screen and (max-width: 1023px) {
  .products__foot .field__label {
    margin-right: 0;
    margin-bottom: 8px;
  }
}
.products__foot .field__wrap-select {
  min-width: 100px;
}
@media only screen and (max-width: 1023px) {
  .products__foot .panel__info {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
    margin-right: 0;
    margin-top: 16px;
  }
}

.products__arrow-active {
  background-color: #0069f6;
  color: #ffffff;
}
body.kyc-body.dark-mode .products__arrow-active {
  color: #111315;
}

@media only screen and (max-width: 1023px) {
  .kyc__table_kyclist .transactions__col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media only screen and (max-width: 1023px) {
  .kyc__table_kyclist .transactions__col_group {
    text-align: right;
  }
}
@media only screen and (max-width: 478px) {
  .kyc__table_kyclist .transactions__col_group {
    text-align: left;
  }
}
.kyc__table_kyclist .transactions__product_warpper + .transactions__product_warpper {
  margin-top: 12px;
}
@media only screen and (max-width: 1023px) {
  .kyc__table_kyclist .transactions__invoice {
    margin-left: 4px;
  }
}

.flag__row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.flag__row .description__col {
  padding: 24px;
  -webkit-transition: all .2s;
  transition: all .2s;
  border-radius: 12px;
  cursor: pointer;
  display: none;
}
.flag__row .description__col:nth-child(1), .flag__row .description__col:nth-child(2), .flag__row .description__col:nth-child(3) {
  display: block;
}

.flag__row .description__col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333%;
          flex: 0 0 33.333%;
  max-width: 33.333%;
  width: 33.333%;
}

.flag__row .description__col:hover, .flag__row .description__col:focus {
  background-color: #F4F4F4;
}
body.kyc-body.dark-mode .flag__row .description__col:hover,
body.kyc-body.dark-mode .flag__row .description__col:focus {
  background-color: #111315;
}

.flag__box {
  text-align: center;
}

.flag__box span {
  display: block;
}

.hide-full {
  display: none !important;
}

.radius__full {
  border-radius: 50%;
}

@media only screen and (max-width: 1023px) {
  .rwd__fullwidth_basis_1023 {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    -ms-flex-preferred-size: inherit;
        flex-basis: inherit;
  }
}

/* Helper */
.vert__middle {
  vertical-align: middle;
}

.d__block {
  display: block;
}

.w-full,
.w-full__switch .switch {
  width: 100%;
}

@media only screen and (max-width: 639px) {
  .avoid_minheight__rwd {
    min-height: 80vh !important;
  }
}
@media only screen and (max-height: 1024px) {
  .avoid_minheight__rwd {
    min-height: 80vh !important;
  }
}
@media only screen and (max-height: 1024px) {
  .avoid_minheight__rwd .entry {
    min-height: calc(var(--vh, 1vh) * 85);
  }
}

.shorten__text {
  white-space: nowrap;
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.shorten__text-wrap > .full__text {
  display: none;
}
.shorten__text.v2 {
  max-width: 120px;
}
@media only screen and (max-width: 1023px) {
  .transactions__col .shorten__text {
    max-width: 300px;
  }
}
@media only screen and (max-width: 320px) {
  .transactions__col .shorten__text {
    max-width: 120px;
  }
}

@media only screen and (min-width: 768px) {
  .shorten__text-wrap {
    position: relative;
    display: inline-block;
  }
  .shorten__text-wrap:hover > .shorten__text {
    visibility: hidden;
  }
  .shorten__text-wrap:hover > .full__text {
    display: block;
    position: absolute;
    background-color: #fff;
    border: 1px solid #efefef;
    border-radius: 5px;
    padding: 5px;
    -webkit-transform: translate(-6px, calc(-100% - 1px));
    transform: translate(-6px, calc(-100% - 1px));
    z-index: 1;
  }
  body.kyc-body.dark-mode .shorten__text-wrap:hover > .full__text {
    background-color: #000;
  }
}
.hide-full {
  display: none !important;
}

.radius__full {
  border-radius: 50%;
}

@media only screen and (max-width: 1023px) {
  .rwd__fullwidth_basis_1023 {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    -ms-flex-preferred-size: inherit;
        flex-basis: inherit;
  }
}

/* Helper */
.vert__middle {
  vertical-align: middle;
}

.d__block {
  display: block;
}

.d__none {
  display: none;
}

.w-full,
.w-full__switch .switch {
  width: 100%;
}

@media only screen and (max-width: 639px) {
  .avoid_minheight__rwd {
    min-height: 80vh !important;
  }
}
@media only screen and (max-height: 1024px) {
  .avoid_minheight__rwd {
    min-height: 80vh !important;
  }
}
@media only screen and (max-height: 1024px) {
  .avoid_minheight__rwd .entry {
    min-height: calc(var(--vh, 1vh) * 85);
  }
}

.tips__item-token-price strong small + small {
  color: #6F767E;
}

.search__fullwidth {
  width: 100%;
}

.actions__kyc-2 .actions__body {
  right: -8px;
}
@media only screen and (max-width: 767px) {
  .actions__kyc-2 .actions__body {
    top: 100%;
  }
}
@media only screen and (max-width: 320px) {
  .actions__kyc-2 .actions__body {
    width: 200px;
  }
}
.actions__body_l {
  width: 320px;
}
.actions__body-xl, .actions__body_xl {
  width: 480px;
}
.actions__option_normal {
  font-size: 13px;
}
.actions__option_normal:hover, .actions__option_normal:focus {
  background-color: transparent;
  color: inherit;
}

.actions__popover .MuiPopover-paper {
  overflow: initial;
  border: 1px solid transparent;
  border-radius: 16px;
  -webkit-box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
}
body.kyc-body.dark-mode .actions__popover .MuiPopover-paper {
  background-color: #272B30;
}
.actions__popover .actions__body {
  position: relative;
  top: 0;
  right: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
  visibility: visible;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.actions__popover .actions__body:before {
  display: none;
}
.actions__popover .actions__body-anchor:before {
  display: inline-block;
}

.popover-tooltip {
  font-family: "Inter", sans-serif;
  font-size: 12px !important;
  font-weight: 500;
  letter-spacing: -.01em;
  max-width: 430px;
  border-radius: 4px;
  background: #9A9FA5;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000;
  color: #F4F4F4 !important;
  line-height: 18px;
}

.popover-tooltip.fullwidth-tooltip {
  max-width: unset;
}

.popover-tooltip.fullwidth-tooltip.__react_component_tooltip.show {
  opacity: 1;
  padding: 0;
}

.sidebar__logo-kyc {
  max-width: 200px;
}

.sidebar__logo-kyc,
.sidebar__logo-kyc img {
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 767px) {
  .sidebar__logo-kyc,
  .sidebar__logo-kyc img {
    max-width: 140px;
  }
}

.sidebar__menu-kyc .sidebar__item[href].active,
.sidebar__menu-kyc .sidebar__help.active {
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #6F767E;
}
.sidebar__menu-kyc .sidebar__item[href].active .icon, .sidebar__menu-kyc .sidebar__help.active .icon {
  fill: #6F767E;
}
body.kyc-body.dark-mode .sidebar__menu-kyc .sidebar__item[href].active .icon,
body.kyc-body.dark-mode .sidebar__menu-kyc .sidebar__help.active .icon {
  fill: #6F767E;
}
.sidebar__menu-kyc .sidebar__item[href].active.is-active, .sidebar__menu-kyc .sidebar__help.active.is-active {
  background: #EFEFEF;
  -webkit-box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px #ffffff;
          box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px #ffffff;
  color: #1A1D1F;
}
body.kyc-body.dark-mode .sidebar__menu-kyc .sidebar__item[href].active.is-active,
body.kyc-body.dark-mode .sidebar__menu-kyc .sidebar__help.active.is-active {
  background: #272B30;
  -webkit-box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.11);
          box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.11);
  color: #FCFCFC;
}
.sidebar__menu-kyc .sidebar__item[href].active.is-active .icon, .sidebar__menu-kyc .sidebar__help.active.is-active .icon {
  fill: #1A1D1F;
}
body.kyc-body.dark-mode .sidebar__menu-kyc .sidebar__item[href].active.is-active .icon, body.kyc-body.dark-mode .sidebar__menu-kyc .sidebar__help.active.is-active .icon {
  fill: #FCFCFC;
}

.sidebar.active .sidebar__head {
  font-size: 15px;
}
.sidebar.active .sidebar__head .icon:first-child {
  margin-right: 12px;
}
.sidebar__help_copyright {
  font-size: 13px;
  margin-top: 24px;
  height: initial;
}
.sidebar__help_copyright:hover, .sidebar__help_copyright:focus {
  color: #6F767E;
}
@media only screen and (max-width: 1259px) {
  .sidebar__help_copyright {
    font-size: 0;
  }
}
.sidebar.active .sidebar__help_copyright {
  font-size: 13px;
}
.sidebar__help_copyright_icon {
  display: none;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
}
@media only screen and (max-width: 1259px) {
  .sidebar__help_copyright_icon {
    display: inline-block;
  }
}
.sidebar.active .sidebar__help_copyright_icon, .sidebar.visible .sidebar__help_copyright_icon {
  display: none !important;
}

.sidebar.sidebar-admin {
  z-index: 30;
  width: 96px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media only screen and (max-width: 767px) {
  .sidebar.sidebar-admin {
    width: 100%;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}
@media only screen and (max-width: 767px) {
  .sidebar.sidebar-admin.visible {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@media only screen and (max-width: 767px) {
  .sidebar.sidebar-admin.visible .sidebar__item-dropdown .sidebar__body {
    display: block !important;
  }
}
.sidebar.sidebar-admin .sidebar__toggle {
  display: inline-block;
}
@media only screen and (max-width: 767px) {
  .sidebar.sidebar-admin .sidebar__toggle {
    display: none;
  }
}
.sidebar.sidebar-admin .sidebar__link, .sidebar.sidebar-admin .sidebar__item, .sidebar.sidebar-admin .sidebar__help {
  width: 48px;
  font-size: 0;
}
@media only screen and (max-width: 767px) {
  .sidebar.sidebar-admin .sidebar__link, .sidebar.sidebar-admin .sidebar__item, .sidebar.sidebar-admin .sidebar__help {
    width: 100%;
    font-size: 15px;
  }
}
.sidebar.sidebar-admin .sidebar__link .icon, .sidebar.sidebar-admin .sidebar__item .icon, .sidebar.sidebar-admin .sidebar__help .icon {
  margin-right: 0;
}
@media only screen and (max-width: 767px) {
  .sidebar.sidebar-admin .sidebar__link .icon, .sidebar.sidebar-admin .sidebar__item .icon, .sidebar.sidebar-admin .sidebar__help .icon {
    margin-right: 12px;
  }
}
.sidebar.sidebar-admin .sidebar__item_dropdown .sidebar__body {
  display: none;
}
@media only screen and (max-width: 767px) {
  .sidebar.sidebar-admin .sidebar__item_dropdown .sidebar__body.active {
    display: block;
  }
}
.sidebar.sidebar-admin .sidebar__item_dropdown.wide .icon:last-child {
  -webkit-transform: rotate(180deg) !important;
          transform: rotate(180deg) !important;
}
.sidebar.sidebar-admin .sidebar__head {
  font-size: 0;
}
@media only screen and (max-width: 767px) {
  .sidebar.sidebar-admin .sidebar__head {
    font-size: 15px;
  }
}
.sidebar.sidebar-admin .sidebar__head .icon:first-child {
  margin-right: 0;
}
@media only screen and (max-width: 767px) {
  .sidebar.sidebar-admin .sidebar__head .icon:first-child {
    margin-right: 12px;
  }
}
.sidebar.sidebar-admin .sidebar__head .icon:last-child {
  display: none;
  margin-right: 0 !important;
  -webkit-transform: rotate(180deg) !important;
          transform: rotate(180deg) !important;
}
@media only screen and (max-width: 767px) {
  .sidebar.sidebar-admin .sidebar__head .icon:last-child {
    display: block;
    -webkit-transform: rotate(0deg) !important;
            transform: rotate(0deg) !important;
  }
}
.sidebar.sidebar-admin + .overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 25;
  background: rgba(244, 244, 244, 0.8);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .2s;
  transition: all .2s;
}
body.kyc-body.dark-mode .sidebar.sidebar-admin + .overlay {
  background: rgba(39, 43, 48, 0.9);
}

.sidebar.sidebar-admin.active {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  width: 300px;
  -webkit-box-shadow: 4px 0 32px rgba(17, 19, 21, 0.05);
          box-shadow: 4px 0 32px rgba(17, 19, 21, 0.05);
}
@media only screen and (max-width: 767px) {
  .sidebar.sidebar-admin.active.visible {
    width: 100%;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
}
.sidebar.sidebar-admin.active .sidebar__toggle {
  position: absolute;
  top: 16px;
  right: 16px;
}
.sidebar.sidebar-admin.active .sidebar__toggle .icon:first-child {
  display: none;
}
.sidebar.sidebar-admin.active .sidebar__toggle .icon:last-child {
  display: inline-block;
}
.sidebar.sidebar-admin.active .sidebar__link, .sidebar.sidebar-admin.active .sidebar__item, .sidebar.sidebar-admin.active .sidebar__help {
  width: 100%;
  font-size: 15px;
}
.sidebar.sidebar-admin.active .sidebar__link .icon, .sidebar.sidebar-admin.active .sidebar__item .icon, .sidebar.sidebar-admin.active .sidebar__help .icon {
  margin-right: 12px;
}
.sidebar.sidebar-admin.active .sidebar__item_dropdown .sidebar__body {
  display: block;
}
@media only screen and (max-width: 767px) {
  .sidebar.sidebar-admin.active .sidebar__item_dropdown .sidebar__body {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .sidebar.sidebar-admin.active .sidebar__item_dropdown .sidebar__body.active {
    display: block;
  }
}
.sidebar.sidebar-admin.active .sidebar__head {
  font-size: 15px;
}
.sidebar.sidebar-admin.active .sidebar__head .icon:last-child {
  display: block;
}
.sidebar.sidebar-admin.active + .overlay {
  opacity: 1;
  visibility: visible;
}

.sidebar__item_tooltip {
  border-radius: 12px !important;
  background-color: #111315 !important;
  font-family: "Inter", sans-serif;
  color: #ffffff !important;
  display: none !important;
}
body.kyc-body.dark-mode .sidebar__item_tooltip {
  color: #111315 !important;
  background-color: #ffffff !important;
}
@media only screen and (max-width: 1259px) {
  .sidebar__item_tooltip {
    display: inline-block !important;
  }
}
@media only screen and (max-width: 767px) {
  .sidebar__item_tooltip {
    display: none !important;
  }
}
.sidebar__item_tooltip::before, .sidebar__item_tooltip:after {
  display: none;
}
.sidebar-admin .sidebar__item_tooltip {
  display: inline-block !important;
}
@media only screen and (max-width: 767px) {
  .sidebar-admin .sidebar__item_tooltip {
    display: none !important;
  }
}

.sidebar.active .sidebar__item_tooltip {
  display: none !important;
}

.page__container {
  max-width: 1419px;
}
@media only screen and (max-width: 1419px) {
  .page__container {
    max-width: 1200px;
  }
}

.page__row.page__row_reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-flow: row-reverse wrap;
          flex-flow: row-reverse wrap;
}
.page__row.page__row_reverse .page__col:last-child {
  padding-right: 8px;
}
.page__row.page__row_reverse .page__col:first-child {
  padding-right: 0;
}

.page__col.page__col-full {
  -ms-flex-preferred-size: calc(100%);
      flex-basis: calc(100%);
  width: calc(100%);
}

.page__col .settings__menu_2 {
  top: 0px;
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  background-color: #FCFCFC;
}
body.kyc-body.dark-mode .page__col .settings__menu_2 {
  background-color: #272B30;
}
body.kyc-body.dark-mode .page__col .settings__menu_2 .help__head {
  color: #FCFCFC;
}

.page.page-admin {
  padding-left: 96px;
}
@media only screen and (max-width: 767px) {
  .page.page-admin {
    padding-left: 0;
  }
}

.entry__logo-kyc {
  width: 100%;
  max-width: 120px;
  height: auto;
}
.entry__logo-kyc.desktop-hide {
  display: none !important;
}
@media only screen and (max-width: 1023px) {
  .entry__logo-kyc.desktop-hide {
    display: inline-block !important;
  }
}

@media only screen and (max-width: 1023px) {
  .login__kyc {
    display: none;
  }
}
.login__logo_2 {
  max-width: 145px;
}
.login__wrapper-img > * {
  background-color: transparent !important;
}

.login__head-kyc .login__info-item + .login__info-item {
  margin-left: 24px;
}
@media only screen and (max-width: 1023px) {
  .login__head-kyc .login__info-item + .login__info-item {
    margin-left: 8px;
  }
}

.login:not(.entry__signin) .login__info:last-child {
  right: 0;
}

@media only screen and (max-width: 1023px) {
  .login__info {
    top: 20px;
  }
}
.login__info-item {
  line-height: 1.6;
}
@media only screen and (max-width: 1023px) {
  .login__info-item-desk {
    display: none;
  }
}
.login__info-item-link {
  color: red;
}
.login__info-item-link-flag {
  position: relative;
  padding: 12px;
  border-radius: 12px 16px;
  background-color: #F4F4F4;
  cursor: pointer;
  margin-left: 4px;
}
.login__info-item-link-flag .icon-flag {
  margin-right: 4px;
  display: inline-block;
}
.login__info-item-link-flag .icon-flag__img {
  width: 24px;
  height: 16px;
}
.login__info-item .no_user {
  margin-right: 16px;
}
.login__info-rwd {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .login__info-rwd {
    display: block;
  }
}
.login__info-rwd .header__burger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 50%;
  background-color: #EFEFEF;
}

.actions.active .header__burger {
  background-color: #1A1D1F;
}
.actions.active .header__burger::before, .actions.active .header__burger::after {
  background-color: #ffffff;
}

.login.entry__signin {
  min-height: initial;
}

.login__footer {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .login__footer {
    position: relative;
    bottom: 0;
    margin: 32px 0 24px;
  }
}
@media only screen and (max-height: 1024px) {
  .login__footer {
    position: relative;
    bottom: 0;
    margin: 32px 0 24px;
  }
}
.login__footer .login__info {
  top: initial;
  right: initial;
  position: relative;
}
.login__footer .login__info + .login__info {
  margin-top: 24px;
}
@media only screen and (max-width: 1023px) {
  .login__footer .login__info {
    padding-left: 0;
  }
}
@media only screen and (max-width: 1023px) {
  .login__footer_translation, .login__footer_copyright {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.login__footer_translation span, .login__footer_copyright span {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
}
@media only screen and (max-width: 1023px) {
  .login__footer_translation span {
    margin-bottom: 12px;
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
}
@media only screen and (max-width: 1023px) {
  .login__footer_translation a {
    margin: 0 12px 8px !important;
  }
}
.login__footer_translation .lang-switch-btn {
  display: none;
}
.login__footer_translation .lang-switch-btn:nth-child(1), .login__footer_translation .lang-switch-btn:nth-child(2), .login__footer_translation .lang-switch-btn:nth-child(3), .login__footer_translation .lang-switch-btn:nth-child(4) {
  display: inline-block;
}
@media only screen and (max-width: 1023px) {
  .login__footer_copyright span {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
    margin-top: 12px;
  }
}
@media only screen and (max-width: 1023px) {
  .login__footer_copyright a {
    margin-left: 12px !important;
    margin-right: 12px !important;
    min-width: 110px;
  }
}
@media only screen and (max-width: 1023px) {
  .login__footer .login__footer_copyright span {
    margin-right: 0;
  }
}
.login__footer_2 {
  width: initial;
  right: 40px;
}

@media only screen and (max-width: 1419px) {
  .login .login__col:first-child {
    width: 340px;
  }
}
@media only screen and (max-width: 1339px) {
  .login .login__col:first-child {
    width: 300px;
  }
}
@media only screen and (max-width: 1339px) {
  .login .login__col:last-child {
    padding-left: 24px;
    padding-right: 24px;
    overflow: hidden;
  }
}

.page.page_simple .login .login__col:first-child {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.page__inner-onboarding {
  min-height: calc(100vh - 240px);
  width: 100%;
}
@media only screen and (max-width: 1259px) {
  .page__inner-onboarding {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media only screen and (max-width: 767px) {
  .page__inner-onboarding {
    padding-left: 0;
    padding-right: 0;
  }
}
.page__inner-onboarding-rwd {
  display: none;
  margin-bottom: 32px;
}
@media only screen and (max-width: 1023px) {
  .page__inner-onboarding-rwd {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .page__inner-onboarding-rwd {
    margin-bottom: 24px;
  }
}
.page__inner-onboarding-rwd .create__preview {
  position: relative;
  padding: 0;
  background: transparent;
  opacity: 1;
  visibility: visible;
  width: 100%;
}
.page__inner-onboarding-rwd .card {
  max-width: 100% !important;
  width: 100% !important;
  background: transparent !important;
  padding-top: 0;
}
.page__inner-onboarding-rwd .card__head {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .page__inner-onboarding .page__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media only screen and (max-width: 1259px) {
  .page__inner-onboarding .page__row .page__col:first-child {
    -ms-flex-preferred-size: calc(100% - 280px);
        flex-basis: calc(100% - 280px);
    width: calc(100% - 312px);
  }
}
@media only screen and (max-width: 1023px) {
  .page__inner-onboarding .page__row .page__col:first-child {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    width: 100%;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
}
@media only screen and (max-width: 1259px) {
  .page__inner-onboarding .page__row .page__col:last-child {
    width: 280px;
  }
}
@media only screen and (max-width: 1023px) {
  .page__inner-onboarding .page__row .page__col:last-child {
    display: none;
  }
}
.page__inner-onboarding .header__link.color {
  color: #83BF6E;
}
.page__inner-onboarding .header__link.color .icon {
  fill: #83BF6E;
}

.page__inner-onboarding .card {
  background-color: rgba(239, 239, 239, 0.35);
  /* box-shadow: inset 0 0 0 2px #efefef; */
  /* box-shadow: 0px 4px 8px -4px rgb(0 0 0 / 10%), inset 0px -1px 1px rgb(0 0 0 / 4%), inset 0px 2px 0px rgb(255 255 255 / 25%); */
}

.page__inner-onboarding .field__input {
  background-color: #ffffff;
}
body.kyc-body.dark-mode .page__inner-onboarding .field__input {
  background-color: #111315;
}

.page__inner-onboarding .header__link.active {
  background-color: #ffffff;
}
body.kyc-body.dark-mode .page__inner-onboarding .header__link.active {
  background-color: #111315;
}

.page__inner-onboarding .sidebar__body {
  display: block;
  padding-top: 8px;
}

.page__inner-onboarding .sidebar__link.active {
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.page__inner-onboarding .sidebar__link.color {
  color: #83BF6E;
}
.page__inner-onboarding .create__line-gray .create__info,
.page__inner-onboarding .radio__text {
  font-weight: 500;
}
.page__inner-onboarding .switch__box {
  background: #dedede;
}
body.kyc-body.dark-mode .page__inner-onboarding .onboarding-sidebar__fieldset .header__link:disabled {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.entry__text-onboarding .sidebar__body {
  display: block;
  margin-left: -20px;
}
.entry__text-onboarding .sidebar__link-normal {
  white-space: unset;
  height: initial;
}
.entry__text-onboarding .sidebar__link-normal:hover, .entry__text-onboarding .sidebar__link-normal:focus {
  color: #6F767E;
}
.entry__text-onboarding .sidebar__link-normal + .sidebar__link-normal {
  margin-top: 32px;
  top: -12px;
}
.entry__text-onboarding svg {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 4px;
  top: 16px;
}
.entry__text-onboarding .sidebar__link-text {
  padding-left: 18px;
  margin-top: 11px;
}

.page__inner-onboarding .card__onfido {
  min-height: initial;
}
.page__inner-onboarding .onfido-mount {
  height: initial;
  position: relative;
}
.page__inner-onboarding .onfido-sdk-ui-Modalinner {
  max-height: initial;
  height: 100%;
}
.page__inner-onboarding .onfido-sdk-ui-Uploader-contentWrapper, .page__inner-onboarding .onfido-sdk-ui-Uploader-uploaderWrapper,
.page__inner-onboarding .onfido-sdk-ui-Uploader-uploadArea,
.page__inner-onboarding .onfido-sdk-ui-Theme-step {
  height: initial;
}
@media only screen and (max-width: 479px) {
  .page__inner-onboarding .onfido-sdk-ui-Modal-inner {
    position: relative;
  }
}

.settings__content-2fa {
  margin-bottom: 24px;
}

.shop .summary__fullwidth {
  -ms-flex-preferred-size: calc(100% - 24px);
      flex-basis: calc(100% - 24px);
  width: calc(100% - 24px);
}
@media only screen and (max-width: 639px) {
  .shop .summary__fullwidth {
    width: 100%;
    margin: 24px 0 0;
  }
}
.shop .summary__fullwidth .summary__preview {
  height: initial;
}
.shop__products_2_column .summary {
  -ms-flex-preferred-size: calc(50% - 24px);
      flex-basis: calc(50% - 24px);
  width: calc(50% - 24px);
}
@media only screen and (max-width: 639px) {
  .shop__products_2_column .summary {
    width: 100%;
  }
}

.summary__video .summary__control {
  display: none;
}
.summary__preview {
  position: relative;
  width: 100%;
  height: initial;
  background: #EFEFEF;
}
body.kyc-body.dark-mode .summary__preview {
  background: #272B30;
}
.summary__preview:before, .summary__preview:after {
  display: none;
}
.summary__preview_aspect_ratio {
  position: relative;
  padding-bottom: calc(7 / 6 * 100%);
}
.summary__preview_aspect_ratio > * {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  height: auto;
  margin: auto;
}
.summary__preview_aspect_ratio .file__wrap {
  height: 100%;
  border-radius: 0;
}
.summary__preview img {
  height: auto;
}
.summary__preview embed {
  height: 100%;
}
.summary video {
  height: 100%;
}

body.kyc-body.dark-mode .file__box {
  color: #FCFCFC;
  -webkit-box-shadow: 0px 12px 13px -6px rgba(0, 0, 0, 0.04);
          box-shadow: 0px 12px 13px -6px rgba(0, 0, 0, 0.04);
}

/* Data Center - */
/* Navigation */
.login__info-kyc a {
  margin-left: 24px;
}

.login__info-kyc span a {
  margin-left: 0px;
}

.login__footer .login__info-kyc a,
.login__footer .login__info-kyc span {
  margin-left: 0;
  margin-right: 24px;
}

.login__footer .login__info-kyc *:last-child {
  margin-right: 0;
}

.login__info-lang .nowrap {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  white-space: nowrap;
  margin-right: 16px;
}

.login__info-lang .header__link {
  padding-left: 0;
  font-size: 13px;
  font-weight: 600;
  color: #1A1D1F;
  -webkit-transition: all .2s;
  transition: all .2s;
}
body.kyc-body.dark-mode .login__info-lang .header__link {
  color: #FCFCFC;
}

.login__info-lang .header__link:hover,
.login__info-lang .header__link:focus {
  color: #0069f6;
}

.login__info-lang .icon-flag {
  margin-right: 4px !important;
}

.header__link-block {
  cursor: pointer;
  width: 100%;
}

.header__link-block .icon-flag {
  display: inline-block;
  margin-right: 12px;
}

.header__link-block .icon-flag img {
  height: 16px;
  width: 24px;
  position: relative;
  top: -1px;
}

.header__link-balance:hover,
.header__link-balance:focus {
  color: #6F767E;
}

.login__copyright {
  position: absolute;
  bottom: 40px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23077;
  color: #9A9FA5;
}
body.kyc-body.dark-mode .login__copyright {
  color: #33383F;
}

.help__list .help__item {
  width: 100%;
  font-weight: bold;
}
.help__list .help__item.button-stroke.active {
  background-color: transparent;
}

.title-dot {
  padding-left: 24px;
}
.title-dot::before {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  top: 52%;
}

.title-primary::before {
  background-color: #2A85FF;
}

.title-dark::before {
  background-color: #1A1D1F;
}

.title-gray::before {
  background-color: #EFEFEF;
}

@media only screen and (max-width: 1023px) {
  .kyc__head_transactionsdetail {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    position: relative;
  }
}
@media only screen and (max-width: 1023px) {
  .kyc__head_transactionsdetail .products__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    width: 100%;
    margin-top: 24px;
  }
}
@media only screen and (max-width: 1023px) {
  .kyc__head_transactionsdetail .button_back {
    position: absolute;
    right: 0;
    top: -4px;
  }
}
@media only screen and (max-width: 478px) {
  .kyc__head_transactionsdetail .button_back .icon {
    margin-right: 0 !important;
  }
}
@media only screen and (max-width: 478px) {
  .kyc__head_transactionsdetail .button_back span {
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  .products__nav_show {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media only screen and (max-width: 767px) {
  .products__nav_show {
    margin-left: 0;
    margin-top: 12px;
  }
}

.actions.actions__kyc {
  z-index: initial;
}

.popup__overlay {
  z-index: -1;
}
body.kyc-body.dark-mode .popup .settings__title,
body.kyc-body.dark-mode .popup .description__title,
body.kyc-body.dark-mode .popup .transactions__col {
  color: #FCFCFC;
}

.popup__wrap-gray {
  background-color: #F4F4F4;
}

.popup__kyc-xxxl .popup__wrap {
  max-width: initial !important;
  width: 85vw !important;
}
@media only screen and (max-width: 1558px) {
  .popup__kyc-xxxl .popup__wrap {
    width: 95vw !important;
  }
}
.popup__kyc-xxl .popup__wrap {
  max-width: initial !important;
  width: 678px !important;
}
.popup__kyc-medium .popup__wrap {
  max-width: 678px !important;
}

.popup__close_v2 {
  right: 48px;
  top: 48px;
}
@media only screen and (max-width: 767px) {
  .popup__close_v2 {
    right: 24px;
    top: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .popup__wrap-v2 {
    width: 100vw !important;
    padding: 0;
  }
}

.MuiAccordion-root.accordion__kyc {
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: transparent;
}
.MuiAccordion-root.accordion__kyc:not(:last-child) {
  margin-bottom: 24px;
}
.MuiAccordion-root.accordion__kyc:not(:last-child) .accordion__kyc_head {
  border-bottom: 1px solid #EFEFEF;
}
.MuiAccordion-root.accordion__kyc::before {
  display: none;
}
.MuiAccordion-root.accordion__kyc_menu {
  background-color: rgba(239, 239, 239, 0.35);
  border-radius: 8px !important;
}
body.kyc-body.dark-mode .MuiAccordion-root.accordion__kyc_menu {
  background-color: rgba(17, 19, 21, 0.5);
}
.MuiAccordion-root.accordion__kyc_menu .accordion__kyc_head {
  position: relative;
  padding: 16px !important;
  border: none !important;
}
.MuiAccordion-root.accordion__kyc .accordion__kyc_head {
  padding: 0;
  cursor: pointer;
  -webkit-transition: color .2s;
  transition: color .2s;
  min-height: initial;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.MuiAccordion-root.accordion__kyc .accordion__kyc_head .MuiAccordionSummary-content {
  margin: 0;
}
.MuiAccordion-root.accordion__kyc .accordion__kyc_head .MuiSvgIcon-root {
  -webkit-transition: all .2s;
  transition: all .2s;
}
.MuiAccordion-root.accordion__kyc .accordion__kyc_head_title {
  position: relative;
  text-transform: capitalize;
}
body.kyc-body.dark-mode .MuiAccordion-root.accordion__kyc .accordion__kyc_head .card__title {
  color: #FCFCFC;
}
body.kyc-body.dark-mode .MuiAccordion-root.accordion__kyc .accordion__kyc_head .MuiSvgIcon-root {
  fill: #FCFCFC;
}
.MuiAccordion-root.accordion__kyc .accordion__kyc_head:hover .MuiSvgIcon-root, .MuiAccordion-root.accordion__kyc .accordion__kyc_head:focus .MuiSvgIcon-root {
  fill: #1A1D1F;
}
body.kyc-body.dark-mode .MuiAccordion-root.accordion__kyc .accordion__kyc_head:hover .MuiSvgIcon-root, body.kyc-body.dark-mode .MuiAccordion-root.accordion__kyc .accordion__kyc_head:focus .MuiSvgIcon-root {
  fill: #FCFCFC;
}
.MuiAccordion-root.accordion__kyc .accordion__kyc_body {
  padding: 24px 0 0;
  color: #6F767E;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  line-height: 1.6;
}
.MuiAccordion-root.accordion__kyc .accordion__kyc_body .popular {
  background-color: #EFEFEF;
  padding: 24px;
  border-radius: 8px;
}
.MuiAccordion-root.accordion__kyc .accordion__kyc_body .popular__head {
  border-color: #e2e2e2;
}

.create__card.accordion__kyc-wrapper .accordion__kyc_head {
  padding: 24px;
}
.create__card.accordion__kyc-wrapper .accordion__kyc_body {
  padding: 0 24px 24px;
}

.products__preview-circle {
  margin-right: 12px;
}

.products__preview-circle,
.products__preview-circle img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

@media only screen and (max-width: 1023px) {
  .products__preview-circle-box {
    width: 104px;
    height: 80px;
    margin-right: 20px;
    border-radius: 8px;
    overflow: hidden;
  }
}
@media only screen and (max-width: 478px) {
  .products__preview-circle-box {
    width: 80px;
    height: 68px;
  }
}
@media only screen and (max-width: 1023px) {
  .products__preview-circle-box img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 8px;
  }
}
@media only screen and (max-width: 1023px) {
  .products__preview-circle-box + .products__details {
    margin-top: 12px;
  }
}

body.kyc-body.dark-mode .kyc__stepper .MuiPaper-root {
  background: #1A1D1F;
}
@media only screen and (max-width: 767px) {
  .kyc__stepper .MuiStepper-root {
    padding: 0px;
  }
}
.kyc__stepper .MuiSvgIcon-root {
  width: 48px;
  height: 48px;
  color: #EFEFEF;
  font-size: 15px;
}
body.kyc-body.dark-mode .kyc__stepper .MuiSvgIcon-root {
  color: #272B30;
}
.kyc__stepper .MuiStepIcon-text {
  fill: #1A1D1F;
}
body.kyc-body.dark-mode .kyc__stepper .MuiStepIcon-text {
  fill: #FCFCFC;
}
.kyc__stepper .MuiSvgIcon-root.MuiStepIcon-active, .kyc__stepper .MuiStep-root.MuiStep-completed .MuiSvgIcon-root {
  color: #2A85FF;
}
body.kyc-body.dark-mode .kyc__stepper .MuiSvgIcon-root.MuiStepIcon-active,
body.kyc-body.dark-mode .kyc__stepper .MuiStep-root.MuiStep-completed .MuiSvgIcon-root {
  color: #2A85FF;
}
.kyc__stepper .MuiSvgIcon-root.MuiStepIcon-active .MuiStepIcon-text {
  fill: #EFEFEF;
}
.kyc__stepper .MuiTypography-body2 {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  color: #6F767E !important;
}
.kyc__stepper .MuiStepIcon-text {
  font-size: 11px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  .kyc__stepper .MuiStepLabel-labelContainer {
    display: none;
  }
}
.kyc__stepper .MuiStepLabel-label.MuiStepLabel-active {
  color: #1A1D1F;
}
body.kyc-body.dark-mode .kyc__stepper .MuiStepLabel-label.MuiStepLabel-active {
  color: #FCFCFC;
}
.kyc__stepper .MuiStepConnector-line {
  color: #EFEFEF;
}

.kyc__stepper.kyc__stepper_32 .MuiSvgIcon-root {
  width: 32px;
  height: 32px;
}

.status-idle {
  background-color: #EFEFEF;
  color: #1A1D1F;
}

.status-pending {
  background-color: #fff3cf;
  color: #ecb610;
}

.status-primary {
  background-color: #e7f1ff;
  color: #2A85FF;
}

.status-red {
  background-color: #FFE7E4;
  color: #FF6A55;
}

.status-purple {
  background-color: #eae5ff;
  color: #8E59FF;
}

.status-green {
  background-color: #EAFAE5;
  color: #83BF6E;
}

.status-kyc__xl {
  padding: 32px;
}

.status-pending-dark,
.status-purple-dark,
.status-green-dark,
.status-primary-dark,
.status-purple-dark,
.status-red-dark,
.status-gray-dark {
  color: #EFEFEF;
  cursor: initial;
}

.status-pending-dark {
  background: #ecb610;
}

.status-primary-dark {
  background: #2A85FF;
}

.status-purple-dark {
  background: #8E59FF;
}

.status-green-dark {
  background: #83BF6E;
}
.status-gray-dark{
  background-color: #EFEFEF;
  color: #6F767E;
}

.status-l {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 16px;
  height: 40px;
  font-size: 13px;
}

[class^="status"].clickable {
  cursor: pointer;
}

.settings__title.have_status .products__status,
.card__title.have_status .products__status {
  margin-left: 8px;
}

.card__information {
  padding: 24px;
  border-radius: 12px;
}
.card__information_title {
  margin-bottom: 16px;
  color: #ffffff;
}
.card__information_content {
  font-weight: 500;
  color: #ffffff;
  line-height: 1.6;
}
.card__information_content p + p {
  margin-top: 16px;
}
.card__information_content ul {
  list-style: circle;
  padding-left: 16px;
}
.card__information_content ul li + li {
  margin-top: 12px;
}
.card__information_content ul li ul {
  margin-top: 12px;
}
.card__information_content a {
  color: #ffffff;
  text-decoration: underline;
  font-weight: 600;
}
.card__information_content a:hover, .card__information_content a:focus {
  text-decoration: none;
}

.card__information-idle {
  background-color: #1A1D1F;
}

.card__information-pending {
  background-color: #ecb610;
}

.card__information-primary {
  background-color: #2A85FF;
}

.card__information-red {
  background-color: #FF6A55;
}

.card__information-purple {
  background-color: #8E59FF;
}

.card__information-green {
  background-color: #83BF6E;
}

.box__information a {
  -webkit-transition: all .2s;
  transition: all .2s;
}
.box__information_2 {
  margin: 0;
}
.box__information_2 .stock__icon {
  margin-right: 12px;
}

.field__fill {
  padding: 16px;
  text-align: center;
}

.box__information-idle .refund__content {
  color: #1A1D1F;
}
.box__information-idle .refund__icon {
  background-color: #EFEFEF;
}
.box__information-idle .refund__icon svg {
  fill: #1A1D1F;
}

.box__information-pending .refund__content {
  color: #ecb610;
}
.box__information-pending .refund__icon {
  background-color: #fff3cf;
}
.box__information-pending .refund__icon svg {
  fill: #ecb610;
}

.box__information-primary .refund__content {
  color: #e7f1ff;
}
.box__information-primary .refund__icon {
  background-color: #2A85FF;
}
.box__information-primary .refund__icon svg {
  fill: #e7f1ff;
}

.box__information-red .refund__content {
  color: #FF6A55;
}
.box__information-red .refund__icon {
  background-color: #FFE7E4;
}
.box__information-red .refund__icon svg {
  fill: #FF6A55;
}

.box__information-purple .refund__content {
  color: #8E59FF;
}
.box__information-purple .refund__icon {
  background-color: #eae5ff;
}
.box__information-purple .refund__icon svg {
  fill: #8E59FF;
}

.box__information-green .refund__content {
  color: #83BF6E;
}
.box__information-green .refund__icon {
  background-color: #EAFAE5;
}
.box__information-green .refund__icon svg {
  fill: #83BF6E;
}

/* Exchange Lists */
.stock__list.exchange__list {
  margin-bottom: -12px;
}
.stock__list.exchange__list .stock__item {
  -ms-flex-preferred-size: calc(25% - 12px);
      flex-basis: calc(25% - 12px);
  width: calc(25% - 12px);
  margin: 0 6px 12px;
}
.stock__list.exchange__list .stock__item:nth-child(1) {
  background-color: #edf8f2;
}
.stock__list.exchange__list .stock__item:nth-child(2) {
  background-color: #ecf9fe;
}
.stock__list.exchange__list .stock__item:nth-child(3) {
  background-color: #f2efff;
}
.stock__list.exchange__list .stock__item:nth-child(4) {
  background-color: #fff7f0;
}
.stock__list.exchange__list .stock__item:nth-child(5) {
  background-color: #fff0ee;
}

@media only screen and (max-width: 1023px) {
  .stock__list.exchange__list_column_3 {
    margin-left: -8px;
    margin-right: -8px;
  }
}
@media only screen and (max-width: 1023px) {
  .stock__list.exchange__list_column_3:before, .stock__list.exchange__list_column_3:after {
    display: none;
  }
}
.stock__list.exchange__list_column_3 .stock__item {
  -ms-flex-preferred-size: calc(33.333% - 12px);
      flex-basis: calc(33.333% - 12px);
  width: calc(33.333% - 12px);
  margin: 0 6px 12px;
}
@media only screen and (max-width: 1259px) {
  .stock__list.exchange__list_column_3 .stock__item {
    -ms-flex-preferred-size: calc(50% - 12px);
        flex-basis: calc(50% - 12px);
    width: calc(50% - 12px);
  }
}
@media only screen and (max-width: 767px) {
  .stock__list.exchange__list_column_3 .stock__item {
    -ms-flex-preferred-size: calc(100% - 12PX);
        flex-basis: calc(100% - 12PX);
    width: calc(100% - 12px);
  }
}

.stock__exchange .input-item-label {
  display: none;
}
.stock__exchange .MuiInput-underline.Mui-disabled::before, .stock__exchange .MuiInput-underline.Mui-disabled::after {
  display: none;
}
.stock__exchange .MuiInputBase-input {
  font-size: 38px;
  font-weight: 600;
  line-height: 1;
}
body.kyc-body.dark-mode .stock__exchange .MuiInputBase-input {
  color: #F4F4F4;
}
@media only screen and (max-width: 767px) {
  .stock__exchange .MuiInputBase-input {
    font-size: 28px;
  }
}
@media only screen and (max-width: 478px) {
  .stock__exchange .MuiInputBase-input {
    font-size: 21px;
  }
}
@media only screen and (max-width: 320px) {
  .stock__exchange .MuiInputBase-input {
    font-size: 16px;
  }
}
.stock__exchange_footer {
  margin-top: 32px;
}

/* ===================================================================
 * # Onfido
 *
 *
 * ------------------------------------------------------------------- */
/* -------------------------------------------------------------------
 * ## Card
 * ------------------------------------------------------------------- */
.card__onfido {
  min-height: 75vh;
}
@media only screen and (max-width: 639px) {
  .card__onfido {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
  }
}
@media only screen and (max-width: 639px) {
  .card__onfido br {
    display: none;
  }
}
@media only screen and (max-width: 639px) {
  .card__onfido .card__information {
    margin-top: 24px;
  }
}

/* -------------------------------------------------------------------
 * ## Onfido Mount *wrapper
 * ------------------------------------------------------------------- */
.onfido-mount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.onfido-mount .onfido-sdk-ui-Modal-inner {
  width: 100%;
  max-width: 100%;
  border: none;
  height: initial;
  max-height: initial;
  position: relative;
  overflow: initial;
}
body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-Modal-inner {
  background-color: #1A1D1F;
}
.onfido-mount .onfido-sdk-ui-Theme-step {
  height: inherit;
  min-height: 578px;
  padding-bottom: 32px;
}
@media only screen and (max-width: 639px) {
  .onfido-mount .onfido-sdk-ui-Theme-step {
    min-height: 320px;
    padding-bottom: 24px;
  }
}

/* -------------------------------------------------------------------
 * ## Typography
 * ------------------------------------------------------------------- */
.onfido-mount .onfido-sdk-ui-Theme-fullHeightContainer .onfido-sdk-ui-PageTitle-titleSpan,
.onfido-mount .onfido-sdk-ui-Theme-content .onfido-sdk-ui-PageTitle-titleSpan {
  font-family: "Inter", sans-serif !important;
  color: #1A1D1F;
  position: relative;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: -.02em;
}
body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-Theme-fullHeightContainer .onfido-sdk-ui-PageTitle-titleSpan, .onfido-mount .onfido-sdk-ui-Theme-fullHeightContainer body.kyc-body.dark-mode .onfido-sdk-ui-PageTitle-titleSpan,
body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-Theme-content .onfido-sdk-ui-PageTitle-titleSpan,
.onfido-mount .onfido-sdk-ui-Theme-content body.kyc-body.dark-mode .onfido-sdk-ui-PageTitle-titleSpan {
  color: #EFEFEF;
}
@media only screen and (max-width: 639px) {
  .onfido-mount .onfido-sdk-ui-Theme-fullHeightContainer .onfido-sdk-ui-PageTitle-titleSpan,
  .onfido-mount .onfido-sdk-ui-Theme-content .onfido-sdk-ui-PageTitle-titleSpan {
    font-size: 18px;
  }
}

.onfido-mount .onfido-sdk-ui-Theme-help, .onfido-mount .onfido-sdk-ui-Theme-fullHeightContainer .onfido-sdk-ui-PageTitle-subTitle,
.onfido-mount .onfido-sdk-ui-Theme-content .onfido-sdk-ui-PageTitle-subTitle, .onfido-mount .onfido-sdk-ui-Welcome-container .onfido-sdk-ui-Welcome-customDescriptions, .onfido-mount .onfido-sdk-ui-DocumentSelector-DocumentList-label, .onfido-mount .onfido-sdk-ui-DocumentSelector-DocumentList-hint, .onfido-mount .onfido-sdk-ui-Uploader-contentWrapper .onfido-sdk-ui-Uploader-documentExampleLabel, .onfido-mount .onfido-sdk-ui-Confirm-previewsContainer .onfido-sdk-ui-Confirm-message, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceSubmit-uploadListItem .onfido-sdk-ui-crossDevice-CrossDeviceSubmit-listText, .onfido-mount .onfido-sdk-ui-crossDevice-Intro-stageMessage, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-QRCode-qrCodeHelpList, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-styledLabel, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-linkText, .onfido-mount .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__menu + span {
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  color: #6F767E;
}

/* -------------------------------------------------------------------
 * ## Button
 * ------------------------------------------------------------------- */
.onfido-mount .ods-button, .onfido-mount .onfido-sdk-ui-crossDevice-MobileConnected-cancel, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-copyToClipboard, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-btn {
  font-family: "Inter", sans-serif !important;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 48px;
  padding: 0 20px;
  border-radius: 12px;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  -webkit-transition: all .2s;
  transition: all .2s;
  background: #2A85FF !important;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
  color: #FCFCFC !important;
}
.onfido-mount .ods-button:hover, .onfido-mount .onfido-sdk-ui-crossDevice-MobileConnected-cancel:hover, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-copyToClipboard:hover, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-btn:hover {
  background: #0069f6 !important;
  color: #FCFCFC !important;
}
.onfido-mount .ods-button:focus, .onfido-mount .onfido-sdk-ui-crossDevice-MobileConnected-cancel:focus, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-copyToClipboard:focus, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-btn:focus, .onfido-mount .ods-button:active, .onfido-mount .onfido-sdk-ui-crossDevice-MobileConnected-cancel:active, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-copyToClipboard:active, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-btn:active, .onfido-mount .ods-button:visited, .onfido-mount .onfido-sdk-ui-crossDevice-MobileConnected-cancel:visited, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-copyToClipboard:visited, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-btn:visited {
  outline: none;
  border-color: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

/*
 * Button primary
 **/
/* -------------------------------------------------------------------
 * ## Link
 * ------------------------------------------------------------------- */
.onfido-mount .onfido-sdk-ui-Theme-link, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-Theme-textButton, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-Theme-link {
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
  color: #6F767E;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-transition: color .2s;
  transition: color .2s;
  border: none;
  text-decoration: underline;
}
.onfido-mount .onfido-sdk-ui-Theme-link:hover, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-Theme-textButton:hover {
  color: #1A1D1F !important;
  background-color: transparent !important;
}
body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-Theme-link:hover, .onfido-mount body.kyc-body.dark-mode .onfido-sdk-ui-Theme-link:hover, body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-Theme-textButton:hover, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container body.kyc-body.dark-mode .onfido-sdk-ui-Theme-textButton:hover {
  color: #FCFCFC !important;
}

/* -------------------------------------------------------------------
 * ## Field
 * ------------------------------------------------------------------- */
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-label, .onfido-mount .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer > label, .onfido-mount .onfido-sdk-ui-CountrySelector-CountryDropdown-countryLabel {
  font-family: "Inter", sans-serif !important;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71429;
  color: #33383F;
}
body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-label, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container body.kyc-body.dark-mode .onfido-sdk-ui-crossDevice-CrossDeviceLink-label, body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer > label, .onfido-mount body.kyc-body.dark-mode .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer > label, body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-CountrySelector-CountryDropdown-countryLabel, .onfido-mount body.kyc-body.dark-mode .onfido-sdk-ui-CountrySelector-CountryDropdown-countryLabel {
  color: #FCFCFC;
}

.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-linkContainer, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-PhoneNumberInput-phoneNumberContainer, .onfido-mount .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__wrapper .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__input {
  width: 100%;
  height: 48px;
  padding: 0 10px;
  border-radius: 12px;
  border: 2px solid transparent;
  background: #F4F4F4;
  font-family: "Inter", sans-serif !important;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
  color: #1A1D1F;
  -webkit-transition: all .2s;
  transition: all .2s;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-linkContainer, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container body.kyc-body.dark-mode .onfido-sdk-ui-crossDevice-CrossDeviceLink-linkContainer, body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-PhoneNumberInput-phoneNumberContainer, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container body.kyc-body.dark-mode .onfido-sdk-ui-PhoneNumberInput-phoneNumberContainer, body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__wrapper .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__input, .onfido-mount .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__wrapper body.kyc-body.dark-mode .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__input {
  background: #272B30;
  color: #ffffff;
}

/* -------------------------------------------------------------------
 * ## Navigation
 * ------------------------------------------------------------------- */
.onfido-mount .onfido-sdk-ui-NavigationBar-navigation {
  height: initial;
  padding: 32px 32px 0;
}
@media only screen and (max-width: 639px) {
  .onfido-mount .onfido-sdk-ui-NavigationBar-navigation {
    padding: 16px;
  }
}
.onfido-mount .onfido-sdk-ui-NavigationBar-back {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 2px solid #EFEFEF;
  border-radius: 50%;
  background: #EFEFEF;
  -webkit-transition: all .2s;
  transition: all .2s;
  width: 64px;
  height: 64px;
  cursor: pointer;
}
@media only screen and (max-width: 639px) {
  .onfido-mount .onfido-sdk-ui-NavigationBar-back {
    width: 40px;
    height: 40px;
  }
}
body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-NavigationBar-back {
  background-color: #6F767E;
  border: 2px solid #6F767E;
}
.onfido-mount .onfido-sdk-ui-NavigationBar-back:hover, .onfido-mount .onfido-sdk-ui-NavigationBar-back:focus {
  background: transparent;
  border-color: #1A1D1F;
}
body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-NavigationBar-back:hover, body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-NavigationBar-back:focus {
  border-color: #1A1D1F;
}
.onfido-mount .onfido-sdk-ui-NavigationBar-iconBack {
  background-color: transparent !important;
  -webkit-transition: all .2s;
  transition: all .2s;
}
.onfido-mount .onfido-sdk-ui-NavigationBar-label {
  display: none !important;
}

/* -------------------------------------------------------------------
 * ## Tips
 * ------------------------------------------------------------------- */
.onfido-mount .onfido-sdk-ui-Theme-help {
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-bottom: 16px;
  font-size: 14px;
  color: #6F767E;
  background-color: #EFEFEF;
  padding: 32px 24px;
  border-radius: 12px;
  text-align: center;
}
body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-Theme-help {
  background-color: #272B30;
}
.onfido-mount .onfido-sdk-ui-Theme-help ul {
  padding: 0;
  margin: 0;
}
.onfido-mount .onfido-sdk-ui-Theme-help li:not(:last-child) {
  margin-bottom: 8px;
}
.onfido-mount .onfido-sdk-ui-crossDevice-MobileConnected-icon + .onfido-sdk-ui-Theme-header {
  font-family: "Inter", sans-serif !important;
  text-decoration: none;
  background: #2A85FF !important;
  font-size: 13px;
  height: 32px;
  line-height: 32px;
  border-radius: 8px;
  color: white !important;
  padding-left: 16px;
  padding-right: 16px;
}
.onfido-mount .onfido-sdk-ui-crossDevice-MobileConnected-cancel {
  background-color: #FF6A55 !important;
  text-decoration: none;
}
.onfido-mount .onfido-sdk-ui-crossDevice-MobileConnected-cancel:hover {
  background-color: #f7553e !important;
}
.onfido-mount .onfido-sdk-ui-Theme-help + .onfido-sdk-ui-crossDevice-MobileConnected-cancel {
  margin-top: 16px;
  margin-bottom: 24px;
}

/* -------------------------------------------------------------------
 * ## Content as general onfido panel
 * ------------------------------------------------------------------- */
/* -------------------------------------------------------------------
 * ## Welcome
 * ------------------------------------------------------------------- */
/* -------------------------------------------------------------------
 * ## Choose your document
 * ------------------------------------------------------------------- */
.onfido-mount .onfido-sdk-ui-DocumentSelector-DocumentList-option {
  border-radius: 12px;
  background: transparent;
  border: none;
  -webkit-box-shadow: 0 0 0 2px #efefef inset;
          box-shadow: 0 0 0 2px #efefef inset;
}
body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-DocumentSelector-DocumentList-option {
  -webkit-box-shadow: 0 0 0 2px #272b30 inset;
          box-shadow: 0 0 0 2px #272b30 inset;
}
.onfido-mount .onfido-sdk-ui-DocumentSelector-DocumentList-option:hover {
  -webkit-box-shadow: 0 0 0 2px #1a1d1f inset !important;
          box-shadow: 0 0 0 2px #1a1d1f inset !important;
}
body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-DocumentSelector-DocumentList-option:hover {
  -webkit-box-shadow: 0 0 0 2px #efefef inset !important;
          box-shadow: 0 0 0 2px #efefef inset !important;
}
.onfido-mount .onfido-sdk-ui-DocumentSelector-DocumentList-option::after {
  display: none;
}
.onfido-mount .onfido-sdk-ui-DocumentSelector-DocumentList-icon {
  width: 56px;
  height: 56px;
}
@media only screen and (max-width: 639px) {
  .onfido-mount .onfido-sdk-ui-DocumentSelector-DocumentList-icon {
    height: 40px;
    width: 40px;
  }
}
.onfido-mount .onfido-sdk-ui-DocumentSelector-DocumentList-optionMain {
  margin-right: 0;
}
.onfido-mount .onfido-sdk-ui-DocumentSelector-DocumentList-label {
  color: #1A1D1F;
}
body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-DocumentSelector-DocumentList-label {
  color: #EFEFEF;
}
.onfido-mount .onfido-sdk-ui-DocumentSelector-DocumentList-hint {
  font-size: 12px;
  font-weight: 500;
  color: #6F767E;
}
.onfido-mount .onfido-sdk-ui-DocumentSelector-DocumentList-hint:last-child {
  margin-bottom: 0;
}
body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-DocumentSelector-DocumentList-hint {
  color: #ffffff;
}

/* -------------------------------------------------------------------
 * ## Uploder *passphoto
 * ------------------------------------------------------------------- */
@media only screen and (max-width: 639px) {
  .onfido-mount .onfido-sdk-ui-Uploader-contentWrapper .onfido-sdk-ui-Uploader-imageQualityGuideRow {
    gap: 16px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
  }
}
.onfido-mount .onfido-sdk-ui-Uploader-contentWrapper .onfido-sdk-ui-Uploader-imageQualityGuideRow:not(:last-child) {
  margin-bottom: 16px;
}
.onfido-mount .onfido-sdk-ui-Uploader-contentWrapper .onfido-sdk-ui-Uploader-imageQualityGuideRow:first-child {
  margin-top: 24px;
}
.onfido-mount .onfido-sdk-ui-Uploader-contentWrapper .onfido-sdk-ui-Uploader-imageQualityGuideRow:last-child {
  margin-bottom: 24px;
}
@media only screen and (max-width: 639px) {
  .onfido-mount .onfido-sdk-ui-Uploader-contentWrapper .onfido-sdk-ui-Uploader-documentExampleCol {
    margin: 0;
  }
}
.onfido-mount .onfido-sdk-ui-Uploader-contentWrapper .onfido-sdk-ui-Uploader-documentExampleLabel {
  font-size: 13px;
}
.onfido-mount .onfido-sdk-ui-Uploader-container .onfido-sdk-ui-Uploader-iconContainer {
  -webkit-box-flex: initial;
      -ms-flex: initial;
          flex: initial;
}
@media only screen and (max-width: 479px) {
  .onfido-mount .onfido-sdk-ui-Uploader-container .onfido-sdk-ui-Uploader-iconContainer {
    height: 90px;
    width: 90px;
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: 479px) {
  .onfido-mount .onfido-sdk-ui-Uploader-container .onfido-sdk-ui-Uploader-iconContainer .onfido-sdk-ui-Uploader-icon {
    width: 90px;
    height: 90px;
    background-size: 40px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
  }
}
.onfido-mount .onfido-sdk-ui-Uploader-contentWrapper,
.onfido-mount .onfido-sdk-ui-Uploader-uploaderWrapper {
  height: initial;
}

/* -------------------------------------------------------------------
 * ## Confirm photo
 * ------------------------------------------------------------------- */
.onfido-mount .onfido-sdk-ui-Confirm-previewsContainer .onfido-sdk-ui-Confirm-message {
  font-size: 14px;
}

/* -------------------------------------------------------------------
 * ## Submit
 * ------------------------------------------------------------------- */
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceSubmit-uploadListItem {
  max-width: 120px;
  margin: 0 auto 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceSubmit-uploadListItem .onfido-sdk-ui-crossDevice-CrossDeviceSubmit-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceSubmit-uploadListItem .onfido-sdk-ui-crossDevice-CrossDeviceSubmit-listText {
  font-size: 14px;
}
.onfido-mount [aria-label="Tips"] {
  margin-bottom: 24px;
}

/* -------------------------------------------------------------------
 * ## Crossdevice *continue to the phone
 * ------------------------------------------------------------------- */
.onfido-mount {
  /*
   * List
   **/
}
.onfido-mount .onfido-sdk-ui-crossDevice-Intro-list {
  max-width: 420px;
  margin: 0 auto;
}
.onfido-mount .onfido-sdk-ui-crossDevice-Intro-stage::before {
  background-color: #EFEFEF;
  left: 1.8375em;
}
body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-crossDevice-Intro-stage::before {
  background-color: #272B30;
}
.onfido-mount .onfido-sdk-ui-crossDevice-Intro-stageIcon {
  width: 64px;
  height: 64px;
  background-color: #EFEFEF;
}
body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-crossDevice-Intro-stageIcon {
  background-color: #33383F;
}
.onfido-mount .onfido-sdk-ui-crossDevice-Intro-stageMessage {
  font-size: 13px;
}

/* -------------------------------------------------------------------
 * ## Crossdevice-link *Get your secure link
 * ------------------------------------------------------------------- */
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container {
  /*
   * Get secure link field
   **/
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-QRCode-qrCodeHelpIcon {
  display: none;
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-Theme-textButton {
  color: #2A85FF !important;
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-Theme-textButton:hover {
  color: #0069f6 !important;
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-QRCode-qrCodeHelpList {
  text-align: center;
  font-size: 14px;
  color: #6F767E;
  background-color: #EFEFEF;
  margin-top: 8px;
  margin-bottom: 0px;
  padding: 16px;
  border-radius: 12px;
}
@media only screen and (max-width: 639px) {
  .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-QRCode-qrCodeHelpList {
    padding: 8px;
  }
}
body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-QRCode-qrCodeHelpList {
  background-color: #272B30;
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-styledLabel {
  margin-top: 16px;
  font-size: 13px;
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-styledLabel:before, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-styledLabel:after {
  border-color: #EFEFEF;
}
body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-styledLabel:before, body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-styledLabel:after {
  border-color: #272B30;
}
@media only screen and (max-width: 639px) {
  .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-styledLabel:before, .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-styledLabel:after {
    display: none;
  }
}
@media only screen and (max-width: 639px) {
  .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-viewOptionsGroup {
    margin-left: 0;
    margin-right: 0;
  }
}
@media only screen and (max-width: 478px) {
  .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-viewOptionsGroup {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 4px;
  }
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-Theme-link {
  font-size: 14px;
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-label {
  margin-bottom: 0;
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-linkText {
  font-size: 14px;
}
body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-linkText {
  color: #ffffff;
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-actionContainer:before {
  display: none !important;
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-copyToClipboard {
  text-decoration: none;
  font-size: 13px;
  height: 32px;
  line-height: 32px;
  border-radius: 8px;
  color: white !important;
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-copyToClipboard:hover {
  background-color: #0069f6 !important;
  color: white !important;
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-numberInputSection {
  position: relative;
}
@media only screen and (max-width: 376px) {
  .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-numberInputSection {
    height: initial;
  }
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-inputContainer {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
  width: 100%;
  background-color: transparent;
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .PhoneInputCountryIcon {
  height: initial;
  width: initial;
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-PhoneNumberInput-flagIcon {
  width: 16px;
  height: 16px;
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .PhoneInputInput {
  font-family: "Inter", sans-serif !important;
  background-color: transparent !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71429;
  color: #33383F;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}
body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .PhoneInputInput {
  color: #FCFCFC;
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-btn {
  text-decoration: none;
  font-size: 13px;
  height: 32px;
  line-height: 32px;
  border-radius: 8px;
  color: white !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  max-width: 80px;
}
@media only screen and (max-width: 376px) {
  .onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-btn {
    position: relative;
    right: initial;
    top: initial;
    -webkit-transform: initial;
            transform: initial;
    height: 48px;
    border-radius: 12px;
    padding: 0 20px;
    font-size: 15px;
    width: 100%;
    max-width: 100%;
    margin-top: 8px;
  }
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-btn:hover {
  background-color: #0069f6 !important;
  color: white !important;
}
.onfido-mount .onfido-sdk-ui-crossDevice-CrossDeviceLink-container .onfido-sdk-ui-crossDevice-CrossDeviceLink-divider {
  display: none;
}

/* -------------------------------------------------------------------
 * ## Search country
 * ------------------------------------------------------------------- */
.onfido-mount .onfido-sdk-ui-Modal-inner .onfido-sdk-ui-Theme-scrollableContent,
.onfido-mount .onfido-sdk-ui-Modal-inner .onfido-sdk-ui-Theme-content {
  overflow: unset;
}
.onfido-mount .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__wrapper .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__input {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.onfido-mount .onfido-sdk-ui-CountrySelector-CountryDropdown-dropdownIcon {
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  top: 12px;
  right: 12px;
}
@media only screen and (max-width: 320px) {
  .onfido-mount .onfido-sdk-ui-CountrySelector-CountryDropdown-dropdownIcon {
    display: none;
  }
}
.onfido-mount .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__menu {
  background: #F4F4F4;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-top: -12px;
  width: calc( 100% + 0px);
  padding-top: 8px;
}
@media only screen and (max-width: 639px) {
  .onfido-mount .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__menu {
    max-height: 128px;
  }
}
body.kyc-body.dark-mode .onfido-mount .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__menu {
  background: #272B30;
}
.onfido-mount .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__option {
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
}
.onfido-mount .onfido-sdk-ui-CountrySelector-CountryDropdown-countryLabel {
  margin-bottom: 0;
}
.onfido-mount .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__option--focused,
.onfido-mount .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__option:hover {
  background: #2A85FF;
}
.onfido-mount .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__option--focused .onfido-sdk-ui-CountrySelector-CountryDropdown-countryLabel,
.onfido-mount .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__option:hover .onfido-sdk-ui-CountrySelector-CountryDropdown-countryLabel {
  color: white !important;
}
.onfido-mount .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__menu + span {
  font-size: 13px;
}

.admin_amount_statistics .income__item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333%;
          flex: 0 0 33.333%;
  max-width: 33.333%;
  padding: 0 16px 0 32px;
}
@media only screen and (max-width: 1023px) {
  .admin_amount_statistics .income__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
    padding: 24px 0px 24px !important;
  }
}
.admin_amount_statistics .income__item:first-child {
  padding-left: 0;
}
@media only screen and (max-width: 1023px) {
  .admin_amount_statistics .income__item:first-child {
    padding-top: 0 !important;
  }
}
.admin_amount_statistics .income__item:last-child {
  padding-right: 0;
}
@media only screen and (max-width: 1023px) {
  .admin_amount_statistics .income__item:last-child {
    padding-bottom: 0 !important;
  }
}
@media only screen and (max-width: 1558px) {
  .admin_amount_statistics .income__item_kyc .income__counter {
    font-size: 40px;
  }
}
@media only screen and (max-width: 1419px) {
  .admin_amount_statistics .income__item_kyc .income__counter {
    font-size: 28px;
  }
}

.tips__list.tips__list-clear {
  margin-bottom: -16px;
}
.tips__list.tips__list-clear .tips__item {
  -ms-flex-preferred-size: calc(33.333% - 32px);
      flex-basis: calc(33.333% - 32px);
  width: calc(33.333% - 32px);
  cursor: initial;
  margin-bottom: 16px;
}
@media only screen and (max-width: 1339px) {
  .tips__list.tips__list-clear .tips__item {
    -ms-flex-preferred-size: calc(50% - 32px);
        flex-basis: calc(50% - 32px);
    width: calc(50% - 32px);
  }
}
@media only screen and (max-width: 1023px) {
  .tips__list.tips__list-clear .tips__item {
    -ms-flex-preferred-size: initial;
        flex-basis: initial;
    width: 100%;
  }
}
.tips__list.tips__list-clear .tips__item .tips__icon {
  overflow: hidden;
}
.tips__list.tips__list-clear .tips__item .tips__icon img {
  max-width: 100%;
}
.tips__list.tips__list-clear .tips__item.tips__item_full {
  -ms-flex-preferred-size: calc(100% - 32px);
      flex-basis: calc(100% - 32px);
  width: calc(100% - 32px);
}

.tips__list.tips__list-one-column .tips__item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(100% - 32px);
          flex: 0 0 calc(100% - 32px);
  max-width: calc(100% - 32px);
}

.tips__item-clear {
  cursor: initial;
}
.tips__item-clear:hover .tips__icon {
  background-color: transparent;
}
.tips__item img {
  width: 100%;
  max-width: 90%;
  margin: auto;
}
.tips__action a {
  color: #2A85FF;
}
.tips__action a:hover, .tips__action a:focus {
  color: #0069f6;
}

.tips__line {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.tips__line * + * {
  margin-top: 8px;
}
body.kyc-body.dark-mode .tips__line .status-green-dark small + small {
  color: #FCFCFC;
}

.customer__item_normal,
.customer__item_normal:hover,
.customer__item_normal:focus {
  cursor: initial;
  color: initial;
}

.customer__item-transaction .customer__avatar .icon {
  width: 24px;
  height: 24px;
  fill: #1A1D1F;
}

.transactions__col .customer__avatar.status-green {
  background-color: #EAFAE5;
}
body.kyc-body.dark-mode .transactions__col .customer__avatar .icon {
  fill: #83BF6E;
}
.transactions__col .customer__user {
  color: #FCFCFC;
}

/* Balance Status */
.header__link-balance .balance__status {
  margin-left: 12px;
  background-color: #B5E4CA;
  color: #1A1D1F;
  display: inline-block;
  padding: 0 8px;
  border-radius: 6px;
  line-height: 32px;
  font-weight: 700;
}
body.kyc-body.dark-mode .header__link-balance .balance__status {
  color: #FCFCFC;
}

.review__item-tokenbalance .review__avatar small {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #2A85FF;
  background-color: #c7dfff;
}

.review__item-tokenbalance .review__title {
  font-size: 26px;
  font-weight: 600;
}

.review__item-tokenbalance:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.packages__item-application_kyc {
  -webkit-transition: all .2s;
  transition: all .2s;
  cursor: pointer;
}
.packages__item-application_kyc:hover .button-stroke, .packages__item-application_kyc:focus .button-stroke {
  background: none;
  -webkit-box-shadow: 0 0 0 2px #1A1D1F inset;
          box-shadow: 0 0 0 2px #1A1D1F inset;
}
body.kyc-body.dark-mode .packages__item-application_kyc:hover .button-stroke, body.kyc-body.dark-mode .packages__item-application_kyc:focus .button-stroke {
  -webkit-box-shadow: 0 0 0 2px #FCFCFC inset;
          box-shadow: 0 0 0 2px #FCFCFC inset;
}
.packages__item-application_kyc:hover .button, .packages__item-application_kyc:focus .button {
  background: #0069f6;
}
.packages__item-application_kyc .packages__line {
  border: none;
  margin: 0;
  padding: 0;
}
.packages__item-application_kyc .packages__line img {
  width: 100%;
}

.popular__item.popular__item-normal:hover .popular__title, .popular__item.popular__item-normal:focus .popular__title {
  color: #1A1D1F;
}
body.kyc-body.dark-mode .popular__item.popular__item-normal:hover .popular__title,
body.kyc-body.dark-mode .popular__item.popular__item-normal:focus .popular__title {
  color: #FCFCFC;
}

.review__avatar small {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  vertical-align: middle;
  display: block;
  background-color: #CABDFF;
  color: #1A1D1F;
  line-height: 48px;
  text-align: center;
}
body.kyc-body.dark-mode .review__avatar small {
  color: #FCFCFC;
}

.review__content-kyc .big {
  font-weight: bold;
  font-size: 21px;
}

.calendar__item.reset .calendar__head {
  cursor: initial !important;
  border-color: #EFEFEF !important;
}
body.kyc-body.dark-mode .calendar__item.reset .calendar__head {
  border-color: #272B30 !important;
}
.calendar__item.reset .calendar__value {
  white-space: nowrap;
}

.comments__content a {
  color: #2A85FF;
  font-size: 14px;
}
.comments__content a:hover, .comments__content a:focus {
  color: #0069f6;
}

.change-tenant__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}
@media only screen and (max-width: 320px) {
  .change-tenant__list {
    display: block;
  }
}
.change-tenant__list + .button-stroke {
  background: none;
  -webkit-box-shadow: 0 0 0 2px #EFEFEF inset;
          box-shadow: 0 0 0 2px #EFEFEF inset;
  margin: 36px auto 0;
}
.change-tenant__list + .button-stroke:hover {
  -webkit-box-shadow: 0 0 0 2px #1A1D1F inset;
          box-shadow: 0 0 0 2px #1A1D1F inset;
  background: none;
}
body.kyc-body.dark-mode .change-tenant__list + .button-stroke:hover {
  -webkit-box-shadow: 0 0 0 2px #FCFCFC inset;
          box-shadow: 0 0 0 2px #FCFCFC inset;
}
.change-tenant__item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(33.333% - 24px);
          flex: 0 0 calc(33.333% - 24px);
  width: calc(33.333% - 24px);
  margin: 0 12px;
  padding: 0;
}
@media only screen and (max-width: 639px) {
  .change-tenant__item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(50% - 24px);
            flex: 0 0 calc(50% - 24px);
    width: calc(50% - 24px);
  }
}
@media only screen and (max-width: 320px) {
  .change-tenant__item {
    margin-left: auto;
    margin-right: auto;
  }
}
.change-tenant__item .users__item {
  padding: 0;
  margin: 0 auto;
  -webkit-box-flex: initial;
      -ms-flex: initial;
          flex: initial;
}
.change-tenant__item .users__avatar {
  -webkit-box-shadow: inset 0 0 0 2px #EFEFEF;
          box-shadow: inset 0 0 0 2px #EFEFEF;
  border-radius: 50%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  padding-bottom: calc(4/7*100%);
  width: 96px;
  height: 96px;
  background-color: #ffffff;
  border: 2px solid white;
}
.change-tenant__item .users__avatar img {
  display: block;
  position: absolute;
  height: auto;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  border-radius: initial;
}

.second-security__link, .second-security__item-note a {
  color: #2A85FF;
}
.second-security__link:hover, .second-security__item-note a:hover, .second-security__link:focus, .second-security__item-note a:focus {
  color: #0069f6;
}

.second-security {
  max-width: 767px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.second-security__stepper {
  width: 100%;
}
.second-security__stepper .MuiStepper-root {
  padding: 0;
}
.second-security__stepper .MuiStepLabel-label,
.second-security__stepper .MuiStepLabel-active {
  font-size: 15px;
  font-weight: 600 !important;
}
.second-security__stepper .MuiStepConnector-line {
  border-color: #EFEFEF;
}
.second-security__head {
  margin-bottom: 48px;
}
.second-security__foot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.second-security__foot *:not(:last-child) {
  margin-bottom: 8px;
}
.second-security__entry {
  max-width: 478px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.second-security__button.button {
  margin-bottom: 16px;
}
.second-security__subtitle {
  border-bottom-width: 1px;
}
.second-security__item-title {
  margin-bottom: 0px;
}
.second-security__item-note {
  margin-top: 16px;
}
.second-security__item-note p:not(:last-child) {
  margin-bottom: 12px;
}
.second-security__item-note ol {
  padding-left: 32px;
  margin-bottom: 12px;
}
.second-security__item-note ol li {
  list-style-type: decimal;
}
.second-security__item-note ol li:not(:last-child) {
  margin-top: 8px;
}
.second-security__item .field__label {
  text-align: center;
}
.second-security__packages {
  border-bottom: none;
}
.second-security__packages .packages__group {
  margin: -80px -32px 0;
}
.second-security__packages .packages__item {
  padding: 0 32px;
}
.second-security__packages .packages__title {
  font-size: 16px;
}
.second-security__packages .packages__content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71429;
  color: #9A9FA5;
}
body.kyc-body.dark-mode .second-security__packages .packages__content {
  color: #33383F;
}
.second-security__tab-item {
  margin-bottom: 24px;
}
.second-security__tab-item.MuiAccordion-rounded.MuiAccordion-root {
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.second-security__tab-item.MuiAccordion-rounded.MuiAccordion-root::before {
  display: none;
}
.second-security__tab-head.MuiButtonBase-root.MuiAccordionSummary-root {
  min-height: initial;
  padding: 0 0 12px;
  border-bottom: 1px solid #EFEFEF;
}
.second-security__tab-head.Mui-expanded .icon {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  fill: #1A1D1F;
}
body.kyc-body.dark-mode .second-security__tab-head.Mui-expanded .icon {
  fill: #FCFCFC;
}
.second-security__tab-head .MuiAccordionSummary-content,
.second-security__tab-head .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}
.second-security__tab-body.MuiAccordionDetails-root {
  padding: 24px 0 12px;
}

.kyc-activity-page__col.page__col {
  -ms-flex-preferred-size: calc(100%);
      flex-basis: calc(100%);
  width: calc(100%);
}

a {
  color: #2A85FF;
}

button.link {
  color: #2A85FF;
}

@media only screen and (max-width: 1023px) {
  .kyc-activity__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
@media only screen and (max-width: 1023px) {
  .kyc-activity__row:first-child {
    display: none;
  }
}
@media only screen and (max-width: 1023px) {
  .kyc-activity__row:nth-child(2n) {
    background-color: rgba(239, 239, 239, 0.35);
  }
  body.kyc-body.dark-mode .kyc-activity__row:nth-child(2n) {
    background-color: rgba(17, 19, 21, 0.5);
  }
}
@media only screen and (max-width: 1023px) {
  .kyc-activity__row:nth-child(2n) .activity__col:last-child {
    border-bottom: none;
  }
}
.kyc-activity__row:nth-child(2) {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.kyc-activity__row:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
@media only screen and (max-width: 1023px) {
  .kyc-activity__table {
    display: block;
    border-radius: 12px;
  }
}
@media only screen and (max-width: 1023px) {
  .kyc-activity__col.activity__col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 16px !important;
    border-bottom: 1px solid #EFEFEF;
  }
}
.kyc-activity__col.transcations__col-action {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.kyc-activity__col .activity__label {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71429;
  color: #6F767E;
}
@media only screen and (max-width: 1023px) {
  .kyc-activity__col .activity__label {
    display: block;
  }
}
.kyc-activity .actions__button {
  background-color: #EFEFEF;
}

.change-language__item .users__avatar {
  border-radius: 8px;
  height: 35px;
  padding-bottom: calc(5/7*100%);
}
.change-language__item .users__avatar img {
  width: 100%;
  height: 100%;
}

.change-tenant__list {
  width: 100%;
}
.change-tenant__list .change-language__item {
  display: none;
}
.change-tenant__list .change-language__item:nth-child(1), .change-tenant__list .change-language__item:nth-child(2), .change-tenant__list .change-language__item:nth-child(3) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 1259px) {
  .sidebar.active .theme__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 4px 2px;
    border-radius: 20px;
  }
  .sidebar.active .theme__box {
    height: 32px;
    margin: 2px;
    border-radius: 12px;
    font-size: 15px;
    display: initial !important;
    text-align: center;
  }
}
/* -------------------------------------------------------------------
 * ## JoyRide Product Tour
 * ------------------------------------------------------------------- */
.kyc-body .react-joyride__overlay {
  background-color: rgba(39, 43, 48, 0.9) !important;
}
.kyc-body .react-joyride__tooltip {
  border-radius: 16px !important;
  padding: 24px !important;
  background: #FCFCFC !important;
  -webkit-box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1) !important;
          box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1) !important;
  font-family: 'Inter', sans-serif;
  font-weight: 500 !important;
  color: #6F767E !important;
}
.kyc-body .react-joyride__tooltip > div > div {
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: left !important;
}
.kyc-body .react-joyride__tooltip > div > div a {
  color: #2A85FF !important;
}
.kyc-body .react-joyride__tooltip > div > div a:hover {
  color: #0069f6 !important;
}
.kyc-body .react-joyride__tooltip > div:nth-child(2) {
  margin-top: 16px;
}
.kyc-body .react-joyride__tooltip > div:nth-child(2) button {
  height: 40px;
  padding: 0 16px !important;
  border-radius: 8px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  -webkit-transition: all .2s;
  transition: all .2s;
}
.kyc-body .react-joyride__tooltip > div:nth-child(2) [data-action="back"] {
  background: none !important;
  -webkit-box-shadow: 0 0 0 2px #EFEFEF inset !important;
          box-shadow: 0 0 0 2px #EFEFEF inset !important;
  color: #1A1D1F !important;
}
.kyc-body .react-joyride__tooltip > div:nth-child(2) [data-action="back"]:hover {
  background: none !important;
  -webkit-box-shadow: 0 0 0 2px #1A1D1F inset !important;
          box-shadow: 0 0 0 2px #1A1D1F inset !important;
}
.kyc-body .react-joyride__tooltip > div:nth-child(2) [data-action="primary"] {
  background-color: #2A85FF !important;
}
.kyc-body .react-joyride__tooltip > div:nth-child(2) [data-action="primary"]:hover {
  background-color: #0069f6 !important;
}
.kyc-body [data-action="close"] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z' /%3E%3C/svg%3E");
  right: 16px !important;
  top: 16px !important;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
}
.kyc-body [data-action="close"]:hover {
  opacity: .75;
}
.kyc-body [data-action="close"] svg {
  display: none;
}

body.kyc-body.dark-mode .kyc-press__item .press__post {
  color: #EFEFEF;
}
body.kyc-body.dark-mode .kyc-press__item .copy-wrap {
  border-color: #1A1D1F !important;
  background-color: #EFEFEF !important;
}

.refund__icon.verified {
  background: #EAFAE5;
}
.refund__icon.verified .icon {
  fill: #83BF6E;
}

.product__wrapper_v2 {
  max-width: 100%;
}
.product__content ul {
  list-style: disc;
  padding-left: 16px;
}
.product__content ul:not(:last-child) {
  margin-bottom: 16px;
}
.product__content ul li + li {
  margin-top: 12px;
}
.product__content + .product__subtitle {
  margin-top: 32px;
}

@media only screen and (max-width: 767px) {
  .entry_loginregister {
    padding-top: 48px;
    min-height: inherit;
  }
}
.entry_loginregister .entry__wrapper {
  max-width: 320px;
  width: 100%;
}
.entry_loginregister .entry__youtube_video {
  margin-top: 40px;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 12px;
  padding-bottom: calc(4/4*100%);
  position: relative;
  -webkit-box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.01);
          box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.01);
}
@media only screen and (max-width: 767px) {
  .entry_loginregister .entry__youtube_video {
    margin-bottom: 42px;
  }
}
.entry_loginregister .entry__youtube_video iframe {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

@media only screen and (max-width: 1023px) {
  .header__control .header__button:not(:last-child),
  .header__item:not(:last-child),
  .header__search:not(:last-child) {
    margin-right: 12px;
  }
}
@media only screen and (max-width: 639px) {
  .header__control .header__button:not(:last-child),
  .header__item:not(:last-child),
  .header__search:not(:last-child) {
    margin-right: 8px;
  }
}

.header .header__item_user {
  margin-right: 0;
}

.header__item_user .header__body {
  z-index: 3;
}

.header.header-admin {
  left: 96px;
}
@media only screen and (max-width: 767px) {
  .header.header-admin {
    left: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .create__preview.dashboard__kyc-sticky,
  .create__preview {
    position: relative;
    opacity: 1;
    visibility: visible;
    padding: 0;
    display: block;
    z-index: initial;
  }
}
@media only screen and (max-width: 1023px) {
  .create__preview.dashboard__kyc-sticky .card,
  .create__preview .card {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: 100%;
  }
}
.create__preview.dashboard__kyc-sticky .card:not(:last-child),
.create__preview .card:not(:last-child) {
  margin-bottom: 8px;
}

.kyc-hit {
  margin-top: 32px;
  padding-top: 32px;
}

.intercom-lightweight-app + iframe {
  display: none !important;
}

/* Adding this that accidentally removed
*/
body.dark-mode .MuiPaper-root {
  color: #EFEFEF;
  background: #1A1D1F;
  border: 1px solid #272B30;
}

.cursor-pointer {
  cursor: pointer !important;
}

.onfido-sdk-ui-crossDevice-CrossDeviceLink-qrCodeContainer svg {
  padding: 5px;
  background-color: white;
}

body.dark-mode input[type="date" i]::-webkit-calendar-picker-indicator {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTIwIDNoLTFWMWgtMnYySDdWMUg1djJINGMtMS4xIDAtMiAuOS0yIDJ2MTZjMCAxLjEuOSAyIDIgMmgxNmMxLjEgMCAyLS45IDItMlY1YzAtMS4xLS45LTItMi0yem0wIDE4SDRWOGgxNnYxM3oiLz48cGF0aCBmaWxsPSJub25lIiBkPSJNMCAwaDI0djI0SDB6Ii8+PC9zdmc+");
}

body.dark-mode .sidebar__item[href],
body.dark-mode .sidebar__help,
body.dark-mode .sidebar__head, .sidebar__link {
  color: #6F767E;
}

body.dark-mode .onfido-sdk-ui-FaceVideo-introBullet {
  color: #6F767E;
}

.shorten__text-wrap > .full__text {
  display: none;
}

@media only screen and (min-width: 768px) {
  .shorten__text-wrap {
    position: relative;
    display: inline-block;
  }

  .shorten__text-wrap:hover > .shorten__text {
    visibility: hidden;
  }

  .shorten__text-wrap:hover > .full__text {
    display: block;
    position: absolute;
    background-color: white;
    border: 1px solid #EFEFEF;
    border-radius: 5px;
    padding: 5px;
    -webkit-transform: translate(-6px, calc(-100% - 1px));
            transform: translate(-6px, calc(-100% - 1px));
    z-index: 1;
  }

  body.dark-mode .shorten__text-wrap:hover > .full__text {
    background-color: black;
  }
}
.actions__option {
  text-align: left;
}

.actions__option .icon {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
